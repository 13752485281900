import { useGetCurrentWebinarSessions } from 'lib/api/session';
import SessionsList from './SessionsList';

const BreakoutSessions = () => {
  const { data: sessions } = useGetCurrentWebinarSessions();

  return (
    <div className="flex flex-col gap-4 mt-6 !h-auto">
      <p>Breakout Sessions:</p>
      {sessions && <SessionsList sessions={sessions} />}
      {!sessions && <p className="text-center">No sessions available</p>}
    </div>
  );
};

export default BreakoutSessions;
