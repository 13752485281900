import React, { useEffect, useState } from 'react';
const Icon = ({ icon }) => {
  const [svgContent, setSvgContent] = useState('');
  useEffect(() => {
    const fetchSvg = async () => {
      const iconPath = `/icons/${icon}.svg`;
      try {
        const response = await fetch(iconPath);
        const svgText = await response.text();
        setSvgContent(svgText);
      } catch (error) {
        console.error('Error fetching SVG:', error);
      }
    };
    fetchSvg();
  }, [icon]);
  return (
    <div
      className="icon"
      dangerouslySetInnerHTML={{ __html: svgContent }}
      aria-label={`${icon} icon`}
    />
  );
};
export default Icon;
