import { useReducer } from 'react';
import { OPEN_LAYOUT_PRESET_TAB } from './contextTypes';
import { OpenLayoutPresetTabContext } from './openLayoutPresetTabContext';
import { openLayoutPresetTabReducer } from './openLayoutPresetTabReducer';

export const OpenLayoutPresetTabState = ({ children }) => {
  const [state, dispatch] = useReducer(openLayoutPresetTabReducer, null);
  const openLayoutPresetTab = (layoutPreset) =>{
    return dispatch({ type: OPEN_LAYOUT_PRESET_TAB, value: layoutPreset });
  }

  return (
    <OpenLayoutPresetTabContext.Provider
      value={{
        openedLayoutPresetTab: state,
        openLayoutPresetTab: openLayoutPresetTab,
      }}
    >
      {children}
    </OpenLayoutPresetTabContext.Provider>
  );
};
