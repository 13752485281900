import { useStageLayout } from 'lib/api/stage';
import React, { FC, useContext, useEffect, useState } from 'react';
import { WEBINAR_THEMES } from 'shared';
import { StyledList } from './custom-layout.styled';
import { LayoutOption, LayoutsOptions } from './layoutOptions';
import styled from 'styled-components';
import { useGetLayoutPresets, useLayoutPresetParams } from 'lib/api/layoutPreset';
import { useGetCurrentEvent } from 'lib/api/event';
import { Button, Menu, MenuItem } from '@material-ui/core';
import Icon from 'components/icon/icon';
import { StageLayoutPresetsItems } from './StageLayoutPresetsItems';
import { LayoutPreset } from 'models/LayoutPreset';

interface Props {
  // eventUrlKey: string;
  // stageUrlKey: string;
  handleDeleteLayoutPreset: (any) => void;
  handleEditLayoutPreset: (any) => void;
  handleDuplicateLayoutPreset?: (any) => void;
  handleClickOnElement: (input: any) => void;
  isLoading: boolean;
  layoutPresets: Array<any>;
  selectedLayoutPreset?: LayoutPreset
  isTab?:boolean
}
export const StageLayoutPresets: FC<Props> = ({isTab, handleClickOnElement, handleDeleteLayoutPreset, handleEditLayoutPreset, handleDuplicateLayoutPreset, layoutPresets, isLoading, selectedLayoutPreset }) => {

  const [presetLayoutOptions, setPresetLayoutOptions] = useState<Array<any>>([]);

  useEffect(() => {
    if (layoutPresets) {
      setPresetLayoutOptions(
        layoutPresets.map((element) => {
          let layoutOption = LayoutsOptions.find((lOption) => {
            return lOption.type == element.type;
          });
          return {
            ...element,
            ...layoutOption,
          };
        }),
      );
    }
  }, [layoutPresets]);



  return (
    <>
      <StageLayoutPresetsItems isTab={isTab} handleClickOnElement={handleClickOnElement} handleDelete={handleDeleteLayoutPreset} handleEdit={handleEditLayoutPreset} handleDuplicate={handleDuplicateLayoutPreset} isLoading={isLoading} presetLayoutOptions={presetLayoutOptions} selectedLayoutPreset={selectedLayoutPreset} ></StageLayoutPresetsItems>
      <StyledList></StyledList>

      </>
  );
};
