import BasicTabs from '@material-ui/core/Tabs';
import { Tab } from './Tab';
import { TabPanel } from './TabPanel';
import { StyledIcon, StyledTitle } from './tabs.styled';
import Icon from 'components/icon/icon';

/**
 * Description:
 *
 * Component for Tabs
 */

const Tabs = ({ options, value, classNames, handleChange, disabled, ...rest }) => (
  <div className="flex gap-4 !h-full !max-h-[84vh]">
    {options.map(({ component }, ind) => (
      <TabPanel value={value} index={ind} key={ind} className={classNames?.tabPanel || ''}>
        {component()}
      </TabPanel>
    ))}
    <BasicTabs
      orientation="vertical"
      value={value}
      className={`${classNames?.tabs}` || ''}
      onChange={handleChange}
      {...rest}
    >
      {options.map(({ tabIcon, icon, title, id }, ind) => (
        <Tab
          key={ind}
          id={`tab-${id}`}
          disabled={disabled}
          label={
            // !gap-2
            <div className="!flex !flex-col ">
              <div className="!mx-auto !rounded-full !bg-gray-200 p-2">
                <Icon icon={tabIcon}></Icon>
              </div>
              {icon && <StyledIcon>{icon()}</StyledIcon>}
              <StyledTitle>{title}</StyledTitle>
            </div>
          }
        />
      ))}
    </BasicTabs>
  </div>
);

export { Tabs };
