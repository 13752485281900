import styled from 'styled-components';

export const StyledTabPanel = styled.div`
  height: 100%;
  background-color: rgb(228 228 231);
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  overflow-x: hidden;
  & > div {
    height: 100%;
  }
`;
