import { Organization } from './Organization';
import { ScheduleItem } from './ScheduleItem';
import { Session } from './Session';
import { Speaker } from './Speaker';
import { Stage } from './Stage';
import { User } from './User';

export interface EventAccess {
  eventId: string;
  eventUrlKey: string;
  role: string;
  userId: string;
}

export interface EventCategory {
  name: string;
  showName: boolean;
  isFeatured: boolean;
  description?: string;
  urlKey: string;
  sortOrder: number;
  dateCreated: string;
  dateUpdated: string;
}

export interface EventTag {
  name: string;
  showName: boolean;
  isFeatured: boolean;
  description?: string;
  urlKey: string;
  sortOrder: number;
  dateCreated: string;
  dateUpdated: string;
}

export interface EventWebhook {
  _id: string;
  URL: string;
  trigger: string;
  event: string | Event;
  dateCreated: string;
  dateUpdated: string;
}

export interface EventResource {
  _id: string;
  resourceUrl: string;
  type: 'file' | 'url';
  originalName?: string;
  title?: string;
  extension?: string;
  url?: string;
  description?: string;
  isEnabled?: boolean;
}

export enum EventFormat {
  Webinar = 'webinar',
  Playlist = 'playlist',
}

export interface Event {
  _id: string;
  title: string;
  organization: Organization;
  descriptionHeading?: string;
  description?: string;
  email?: string;
  emailSender?: string;
  ccEmail?: string;
  receptionTitle?: string;
  welcomeMessage: string;
  disableReceptionVideoAutoplay: boolean;
  urlKey: string;
  category?: string | EventCategory;
  tags: EventTag[];
  webhooksApiKey: string;
  suppressSoonReminders: boolean;
  suppressSoonerReminders: boolean;
  isPublished: boolean;
  isSample: boolean;
  isFeatured: boolean;
  isWhiteLabel: boolean;
  extendMaxDuration: boolean;
  hideSchedule: boolean;
  hideSponsors: boolean;
  hidePeople: boolean;
  canBuyTicketsWhileOngoing: boolean;
  sortOrder?: number;
  password?: string;
  eventType: string;
  eventFormat: EventFormat;
  team: {
    user: User;
    role: 'host' | 'moderator';
    description?: string;
    invitation?: string;
  }[];
  imageUrl?: string;
  networkingImageUrl?: string;
  cocktailTablesImageUrl?: string;
  videoProvider?: string;
  videoId?: string;
  logoUrl?: string;
  mainImageUrl?: string;
  primaryColor?: string;
  secondaryColor?: string;
  layoutKey?: string;
  websiteUrl?: string;
  notFoundPageUrl?: string;
  twitterHandle?: string;
  instagramUserName?: string;
  facebookUserName?: string;
  linkedInUserName?: string;
  metaDescription?: string;
  gaTrackingId?: string;
  customDomain?: string;
  showAttendees: boolean;
  showRegistrations: boolean;
  ticketCurrency?: string;
  hideRegistrationsInfo: boolean;
  hideUnavailableTickets: boolean;
  disabledRegistrations: boolean;
  sentRegistrationsThresholdAlert: boolean;
  suppressUserRegisteredEmailUpdateForOrganizers: boolean;
  suppressRegistrationEmails: boolean;
  segments: {
    segmentType?: string;
    title?: string;
    sortOrder?: number;
  }[];
  stages: Stage[];
  mainStage: Stage;
  speakers: Speaker[];
  scheduleItems: ScheduleItem[];
  startsAt: string;
  endsAt: string;
  timezone: string;
  registrationFields: {
    label?: string;
    type?: { type: string };
    placeholder?: string;
    values?: string[];
    isRequired: boolean;
  }[];
  resource: EventResource[];
  webinarHeaderButtons: {
    title: string;
    link: string;
  }[];
  sessions?: Session[] | string[];
  webinarRegistrationPageBottomHeading?: string;
  webinarRegistrationPageBottomSubHeading?: string;
  webinarRegistrationPageBottomButtonText?: string;
  showHostsAtRegistrationPage: boolean;
  disableDarkenedColors: boolean;
  integrations?: {
    driftCode?: string;
    intercomAppId?: string;
  };
  webhooks: EventWebhook[];
  isOngoing: boolean;
  isCompleted: boolean;
  eventAccess?: EventAccess;
  timeUntilStartFriendly?: string;
  isAutomated?: boolean;
  startsAtUtc: string;
  endsAtUtc: string;
  dateCreated: string;
  dateUpdated: string;
  createdBy: string | User;
}
