import { Button, Grid, Menu, MenuItem } from '@material-ui/core';
import Icon from 'components/icon/icon';
import { LayoutOption, LayoutsOptions } from './layoutOptions';
import { StyledList } from './custom-layout.styled';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { WEBINAR_THEMES } from 'shared';
import { LayoutPreset } from 'models/LayoutPreset';
import { videoTypesMap } from 'shared/configs/videoTypes';
import Carousel from 'react-material-ui-carousel';
import { Tooltip } from 'components';

interface Props {
  presetLayoutOptions: Array<any>;
  handleClickOnElement: (input: LayoutPreset) => void;
  handleEdit: (input: LayoutPreset) => void;
  handleDelete: (input: LayoutPreset) => void;
  handleDuplicate: (input: LayoutPreset) => void;
  selectedLayoutPreset: LayoutPreset;
  isLoading: boolean;
  isTab?: boolean;
}
export const StyledItem = styled.div<{ selected: boolean; disabled: boolean }>`
  height: 100%;
  width: 100%;
  color: ${({ selected, theme }) =>
    selected ? theme.additionalColors.primaryColor : WEBINAR_THEMES.GREY};
  border-radius: 8px;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  transition: color 0.2s ease;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)}

  &:hover {
    color: ${({ theme }) => {
      return theme.additionalColors.primaryColor;
    }};
  }
`;
export const StageLayoutPresetsItems: FC<Props> = ({
  isTab,
  presetLayoutOptions,
  handleClickOnElement,
  handleEdit,
  handleDelete,
  handleDuplicate,
  selectedLayoutPreset,
  isLoading,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // const open = Boolean(anchorEl);
  const [open, setOpen] = React.useState(null); // <-- add state to hold id
  // event: React.MouseEvent<HTMLButtonElement>
  const handleClick = (event, lId) => {
    setOpen(lId);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(null);
  };

  const handleMenuDeleteClick = (l) => {
    handleClose();
    handleDelete(l);
  };

  const handleMenuEditClick = (l) => {
    handleClose();
    handleEdit(l);
  };
  const [presetLayoutOptionsGroups, setPresetLayoutOptionsGroups] = useState([]);
  const numberOfElementsToShowCarousel = 6;
  
  useEffect(() => {
    const arr = [];
    for (let i = 0; i < presetLayoutOptions.length; i += numberOfElementsToShowCarousel) {
      arr.push(presetLayoutOptions.slice(i, i + numberOfElementsToShowCarousel));
    }
    setPresetLayoutOptionsGroups(arr);
  }, [presetLayoutOptions]);


  const handleMenuDuplicateClick = (l) => {
    handleClose();
    handleDuplicate(l);
  };

  return !isTab ? (
    <StyledList>
      <Carousel
        autoPlay={false}
        key="layout-presets-carousel"
        className="!px-14 grow text-center "
        navButtonsAlwaysVisible={true}
        swipe={false}
        animation="slide"
        fullHeightHover={false}
      >
        {presetLayoutOptionsGroups.map((group, groupIndex) => (
          <Grid
            container
            spacing={2}
            key={`carousel-grid-container-${groupIndex}`}
            className="place-content-evenly grow "
          >
            {group.map((l, index) => (
              <Grid item xs={2} key={`carousel-grid-item-${l._id}`} className="text-center	">
                <div key={`carousel-item-${index}`} className="relative group ">
                  <div>
                    <Button
                      id="basic-button"
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={(e) => handleClick(e, l._id)}
                      className={`justify-self-center !absolute !flex !items-end !justify-end opacity-0 group-hover:opacity-100 transition-opacity duration-300 ${
                        isTab
                          ? '!mr-0 !top-[-8px] !right-[-4px]'
                          : '!ml-[20%] !top-0 !right-0 !left-0 '
                      }`}
                    >
                      <Icon icon="dots-horizontal" />
                    </Button>
                    <Menu
                      id={`basic-menu-${index}`}
                      anchorEl={anchorEl}
                      key={`menu-${l._id}`}
                      open={open === l._id}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      <MenuItem key={`edit-${l._id}`} onClick={() => handleMenuEditClick(l)}>
                        Edit
                      </MenuItem>

                      <MenuItem
                        key={`duplicate-${l._id}`}
                        onClick={() => handleMenuDuplicateClick(l)}
                      >
                        Duplicate
                      </MenuItem>

                      <MenuItem key={`delete-${l._id}`} onClick={() => handleMenuDeleteClick(l)}>
                        Remove
                      </MenuItem>
                    </Menu>
                  </div>
                  <Tooltip
                    title={
                      <span>
                        {l.sources.map((item, i) => (
                          <React.Fragment key={`preset-${l.id}-fragment-${i}`}>
                            {i + 1}. {item.firstName} {item.lastName} : {item.videoType}
                            <br />
                          </React.Fragment>
                        ))}
                      </span>
                    }
                  >
                    <StyledItem
                      key={index}
                      tabIndex={0}
                      selected={
                        selectedLayoutPreset &&
                        typeof selectedLayoutPreset !== 'string' &&
                        l._id === selectedLayoutPreset._id
                      }
                      onClick={() => handleClickOnElement(l)}
                      disabled={isLoading}
                      className={`${
                        isTab ? '!w-16 !h-12' : '!w-36 !h-20  m-auto mb-1 max-w-full 	'
                      }`}
                    >
                      {l.comp({})}
                      <div className="text-xs	w-full max-w-full truncate">{l.label}</div>
                    </StyledItem>
                  </Tooltip>
                </div>
              </Grid>
            ))}
          </Grid>
        ))}
      </Carousel>
    </StyledList>
  ) : (
    <StyledList>
      {presetLayoutOptions.map((l, index) => (
        <div key={`presetLayoutOption-${index}`} className="relative group">
          <div>
            <Button
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={(e) => handleClick(e, l._id)}
              className={`!absolute !flex !items-end !justify-end opacity-0 group-hover:opacity-100 transition-opacity duration-300 ${
                isTab ? '!mr-0 !top-[-8px] !right-[-4px]' : '!mr-1 !top-0 !right-0'
              }`}
            >
              <Icon icon="dots-horizontal" />
            </Button>
            <Menu
              id={`basic-menu-${index}`}
              anchorEl={anchorEl}
              key={`menu-${l._id}`}
              open={open === l._id}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem key={`edit-${l._id}`} onClick={() => handleMenuEditClick(l)}>
                Edit
              </MenuItem>

              <MenuItem key={`duplicate-${l._id}`} onClick={() => handleMenuDuplicateClick(l)}>
                Duplicate
              </MenuItem>

              <MenuItem key={`delete-${l._id}`} onClick={() => handleMenuDeleteClick(l)}>
                Remove
              </MenuItem>
            </Menu>
          </div>
          <Tooltip
            title={
              <span>
                {l.sources.map((item, i) => (
                  <React.Fragment key={i}>
                    {i + 1}. {item.firstName} {item.lastName} : {item.videoType}
                    <br />
                  </React.Fragment>
                ))}
              </span>
            }
          >
            <StyledItem
              key={index}
              tabIndex={0}
              selected={
                selectedLayoutPreset &&
                typeof selectedLayoutPreset !== 'string' &&
                l._id === selectedLayoutPreset._id
              }
              onClick={() => handleClickOnElement(l)}
              disabled={isLoading}
              className={`${isTab ? '!w-16 !h-12' : '!w-36 !h-28'}`}
            >
              {l.comp({})}
            </StyledItem>
          </Tooltip>
        </div>
      ))}
    </StyledList>
  );
};
