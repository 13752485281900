import { ContainedLayout, CroppedLayout, NewsLayout, PIPLayout, PresentationLayout } from './Layouts';
import { StaticOneAndOneSpotNewsLayout, StaticTwoAndOneSpotPresentationLayout, StaticTwoByTwoContainedStaticLayout, StaticTwoByTwoCroppedStaticLayout, StaticTwoPIPLayout } from './LayoutsWithNumbers'
import { StageLayout } from 'models/Stage';
import { FC } from 'react';


export interface LayoutOption
{
    type: StageLayout;
    comp: FC,
    isStatic: boolean,
    numberOfElements?: number
}
export const LayoutsOptions: Array<LayoutOption> = [
    // { type: StageLayout.Cropped, comp: CroppedLayout, isStatic: false },
    // { type: StageLayout.Contained, comp: ContainedLayout, isStatic: false },
    // { type: StageLayout.Presentation, comp: PresentationLayout, isStatic: false },
    // { type: StageLayout.News, comp: NewsLayout, isStatic: false },
    // { type: StageLayout.PictureInPicture, comp: PIPLayout, isStatic: false },

    { type: StageLayout.Dynamic, comp: CroppedLayout, isStatic: false },
    { type: StageLayout.StaticCropped, comp: StaticTwoByTwoCroppedStaticLayout, isStatic: true, numberOfElements: 2 },
    { type: StageLayout.StaticContained, comp: StaticTwoByTwoContainedStaticLayout, isStatic: true, numberOfElements: 2 },
    { type: StageLayout.StaticPresentation, comp: StaticTwoAndOneSpotPresentationLayout, isStatic: true, numberOfElements: 3 },
    { type: StageLayout.StaticNews, comp: StaticOneAndOneSpotNewsLayout, isStatic: true, numberOfElements: 2 },
    { type: StageLayout.StaticPictureInPicture, comp: StaticTwoPIPLayout, isStatic: true, numberOfElements: 2 },

];
