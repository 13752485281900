import { ListItem } from '@material-ui/core';
import styled from 'styled-components';

export const StyledContainer = styled.div`
  padding-top: 8px;
  padding-right: 8px;
  row-gap: 16px;
  grid-template-rows: 30px 1fr;
  padding-left: 10px;
`;

export const StyledForm = styled.form`
  display: grid;
  width: 100%;
  row-gap: 20px;
  font-size: 14px;
  .MuiInputBase-root,
  .MuiFormControlLabel-label {
    font-size: inherit;
  }
`;

export const StyledOptionItem = styled((props) => <ListItem disableGutters {...props} />)`
  &.MuiListItem-root {
    display: grid;
    grid-template-columns: 24px 1fr 24px;
    column-gap: 0.5rem;
    cursor: move;
     ${(props) => !props.error && 'align-items: flex-end;'}
`;
