import { createContext, useContext, useRef } from 'react';

const ComponentInstanceContext = createContext(null);

export const ComponentInstanceProvider = ({ children }) => {
  const instanceRef = useRef(null);

  return (
    <ComponentInstanceContext.Provider value={instanceRef}>
      {children}
    </ComponentInstanceContext.Provider>
  );
};

export const useComponentInstance = () => {
  return useContext(ComponentInstanceContext);
};
