import { useCallback, useContext } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Mixpanel } from 'services';
import { openMobile } from 'assets/images/webinar';
import { CollapseRightSidebarContext } from 'contexts';
import {
  Device,
  useEventUrlKey,
  useWindowSize,
  MIXPANEL_EVENTS,
  getImageForDimensions,
} from 'shared';
import { Avatar } from 'components';
import { Notifications } from './Notifications';
import { WebinarStartsBlock } from './WebinarStartsBlock';
import {
  StyledWebinarHeader,
  StyledLogoImage,
  StyledWrapper,
  StyledEventInfoBlock,
  StyledWebinarTitle,
  StyledActionsButtonsWrapper,
  StyledNotificationWrapper,
  StyledButtonsList,
  StyledItem,
  StyledToggleSidebarButton,
  StyledButtonP,
} from './header.styled';
import { useGetCurrentEvent } from 'lib/api/event';
import { useGetCurrentUser } from 'lib/api/user';
import { useWebinarTheme } from 'lib/webinar';
import { EventFormat } from 'models/Event';

export const Header = () => {
  const { pathname } = useLocation();
  const { setCollapseRightSidebar } = useContext(CollapseRightSidebarContext);
  const eventUrlKey = useEventUrlKey();
  const isMobile = Device.mobile(useWindowSize());
  const { data: webinar } = useGetCurrentEvent();
  const { data: user } = useGetCurrentUser();

  const { itemsColor, additionalColors } = useWebinarTheme(webinar);

  const openRightSidebar = useCallback(
    () => setCollapseRightSidebar(false),
    [setCollapseRightSidebar],
  );

  const handleCTAButtonClick = (ctaButton) => {
    Mixpanel.track(MIXPANEL_EVENTS.webinarHeaderButtonClicked.success, {
      eventId: webinar._id,
      userId: user._id,
      webinarHeaderButtonId: ctaButton._id,
      webinarHeaderButtonTitle: ctaButton.title,
    });
    window.open(ctaButton.link, '_blank');
  };

  const isBackstagePage = pathname.includes('/backstage');

  const logo = webinar.mainImageUrl || webinar.logoUrl;
  return (
    <StyledWebinarHeader>
      <StyledEventInfoBlock className="flex flex-row gap-4">
        {logo && (
          <Link to={`/webinars/${eventUrlKey}`}>
            <StyledLogoImage src={logo} alt="Webinar logo" />
          </Link>
        )}
        <StyledWrapper>
          <div>
            <StyledWebinarTitle>{webinar.title}</StyledWebinarTitle>
            {webinar.eventFormat === EventFormat.Webinar && <WebinarStartsBlock />}
          </div>

          {isMobile && (
            <StyledToggleSidebarButton onClick={openRightSidebar}>
              {openMobile(itemsColor)}
            </StyledToggleSidebarButton>
          )}
        </StyledWrapper>
      </StyledEventInfoBlock>

      {!!webinar.webinarHeaderButtons.length && !isBackstagePage && (
        <StyledButtonsList>
          {webinar.webinarHeaderButtons.map((ctaButton) => (
            <StyledItem
              key={ctaButton.title}
              theme={additionalColors.primaryColor}
              role="button"
              onClick={() => handleCTAButtonClick(ctaButton)}
            >
              <StyledButtonP>{ctaButton.title}</StyledButtonP>
            </StyledItem>
          ))}
        </StyledButtonsList>
      )}

      <StyledActionsButtonsWrapper>
        <StyledNotificationWrapper>
          <Notifications />
        </StyledNotificationWrapper>
        <Avatar size={40} src={getImageForDimensions(user.profileImageUrl, 100, 100)} />
      </StyledActionsButtonsWrapper>
    </StyledWebinarHeader>
  );
};
