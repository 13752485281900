export const micMute = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22">
    <path
      d="M 17.875 11 C 17.875 11.775 17.746 12.522 17.509 13.218 L 16.39 12.099 C 16.463 11.737 16.5 11.369 16.5 11 L 16.5 9.625 C 16.5 9.245 16.808 8.937 17.188 8.937 C 17.567 8.937 17.875 9.245 17.875 9.625 Z M 11 16.5 C 12.125 16.5 13.17 16.163 14.042 15.583 L 15.029 16.571 C 14.047 17.283 12.894 17.721 11.688 17.841 L 11.688 20.625 L 15.813 20.625 C 16.192 20.625 16.5 20.933 16.5 21.313 C 16.5 21.692 16.192 22 15.813 22 L 6.188 22 C 5.808 22 5.5 21.692 5.5 21.313 C 5.5 20.933 5.808 20.625 6.188 20.625 L 10.313 20.625 L 10.313 17.841 C 6.799 17.488 4.125 14.531 4.125 11 L 4.125 9.625 C 4.125 9.245 4.433 8.937 4.813 8.937 C 5.192 8.937 5.5 9.245 5.5 9.625 L 5.5 11 C 5.5 14.038 7.962 16.5 11 16.5 Z M 15.125 4.125 L 15.125 10.834 L 13.75 9.459 L 13.75 4.125 C 13.757 2.633 12.572 1.408 11.081 1.364 C 9.59 1.32 8.336 2.473 8.254 3.963 L 7.092 2.801 C 7.742 0.891 9.679 -0.269 11.67 0.059 C 13.661 0.387 15.122 2.107 15.125 4.125 Z"
      fill="currentColor"
    />
    <path
      d="M 13.043 14.585 L 12.015 13.556 C 11.168 13.892 10.209 13.787 9.455 13.275 C 8.701 12.763 8.25 11.911 8.25 11 L 8.25 9.793 L 6.875 8.418 L 6.875 11 C 6.875 12.47 7.656 13.829 8.927 14.568 C 10.198 15.306 11.766 15.313 13.043 14.585 Z M 2.263 1.862 L 18.763 18.362 L 19.737 17.388 L 3.237 0.888 Z"
      fill="currentColor"
    />
  </svg>
);
