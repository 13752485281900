import { Event } from './Event';
import { LayoutPreset } from './LayoutPreset';
import { User } from './User';

export enum StageVideoProvider {
  Studio = 'Studio',
  YouTube = 'YouTube',
  Vimeo = 'Vimeo',
  Wistia = 'Wistia',
  Library = 'Library',
}

export enum StageLayout {
  /** Currently Not Used kept for prossibilty to exapand later */
  Cropped = 'cropped',
  Contained = 'contained',
  Presentation = 'presentation',
  News = 'news',
  PictureInPicture = 'pictureinpicture',
  /*****/
  Dynamic = 'dynamic',

  StaticContained = 'static-twosidebysidecontained',
  StaticCropped = 'static-twosidebysidecropped',
  StaticPresentation = 'static-twoandonespotpresentation',
  StaticNews = 'static-oneandonespotnews',
  StaticPictureInPicture = 'static-twopictureinpicture'
}
export const StageLayoutMap = {
  'dynamic': 'All Feeds',
  'static-twosidebysidecontained': 'Two feeds - Side by Side (Contained)',
  'static-twosidebysidecropped': 'Two feeds - Side by Side (Cropped)',
  'static-twoandonespotpresentation': 'Three feeds - Presentation (Contained)',
  'static-oneandonespotnews': 'Two feeds - News Spot',
  'static-twopictureinpicture': 'Two feeds - Picture in Picture',
}
export interface LayoutAssets {
  backgroundColor?: string;
  backgroundImage?: string;
}

export interface StageStream {
  id: string;
}

export interface Stage {
  _id: string;
  event: string | Event;
  exhibitor: string;
  title: string;
  description?: string;
  urlKey: string;
  imageUrl?: string;
  promoImageUrl?: string;
  promoLinkUrl?: string;
  isFeatured: boolean;
  sortOrder?: number;
  isRecorded: boolean;
  showViewers: boolean;
  sponsor?: string;
  videoProvider?: StageVideoProvider;
  videoId?: string; // the last part (id) of the URL
  videoSlug?: string;
  syncWordsSessionId?: string;
  simulcastTargets?: {
    rtmpStreamUrl?: string;
    rtmpStreamKey?: string;
    streamTitle?: string;
  }[];
  members: User[];
  liveStream?: Record<string, unknown>;
  isLiveStreamActive: boolean;
  isBroadcasted: boolean;
  rtmpStreamUrl: string;
  rtmpStreamKey: string;
  videoRoom: string;
  backStageVideoRoom: {
    _id: string;
    providerId: string;
  };
  chatRoom: string;
  backStageChatRoom: string;
  backstageKey?: string;
  recordIndividualStreams: boolean;
  individualStreamsArchiveId?: string;
  liveStreamUrl: string;
  layout: StageLayout;
  layoutPreset?: LayoutPreset | string;
  layoutAssets?: LayoutAssets;
  streams?: StageStream[];
  allowAttendeesToStream?: boolean;
  experienceComposer?: any;
  dateCreated: string;
  dateUpdated: string;
}
