import { useContext, useEffect, useRef, useState } from 'react';
import { OpenRightSidebarTabContext } from 'contexts';
import { WEBINAR_RIGHT_SIDEBAR_TABS } from 'shared';
import styled from 'styled-components';
import Badge from '@material-ui/core/Badge';
import { WEBINAR_THEMES } from 'shared';
import { Tabs } from 'components';
import { StyledTitle } from 'components/ui/Tabs/tabs.styled';
import { useGetCurrentEvent } from 'lib/api/event';
import { useIsOrganizerOfCurrentWebinar, useIsSpeakerOfCurrentWebinar, useWebinarTheme } from 'lib/webinar';
import { EngageTabs } from './Engage/Engage';
import { Resources } from './Resources';
import { useChatContext } from '../ChatContext';
import { useGetPolls, usePollParams, usePollsPusherEvents } from 'lib/api/poll';
import { useSendNotification } from 'lib/notifications';
import { useGetQuestions, useQuestionParams, useQuestionsPusherEvents } from 'lib/api/qa';
import { useGetResources, useResourcePusherEvents } from 'lib/api/resources';
import { useGetCurrentSession } from 'lib/api/session';
import { Layouts } from './Engage/Layouts';
import { Polls } from './Engage/Polls';
import { QA } from './Engage/Q&A';
import { Chat } from './Engage/Chat';
import BreakoutSessions from './BreakoutSessions/BreakoutSessions';
import Background from 'Webinar/LiveStream/Assets/Background';
import { Sources } from './Engage/Sources';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledTabs = styled(({ disabled, options, ...rest }) => <Tabs {...rest} disabled={disabled} options={options} />)`
  &.MuiTabs-root {
    position: sticky;
    top: 0;
    z-index: 10;
    min-height: 50px;
    min-width: fit-content;
    border-radius: 12px;
    padding: 5px;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'initial')};
  }

  & .MuiTabs-fixed {
    display: flex;
  }

  & .MuiTabs-flexContainer {
    width: 100%;
    height: ${({ options }) => (options?.length > 3 ? '100%' : '40%')};
    justify-content: space-between;
    border-radius: 12px;
  }

  & .MuiTabs-indicator {
    display: none;
  }

  .MuiTab-root {
    min-height: 40px;
    border-radius: 12px;
    overflow: visible;
  }

  .MuiTab-root.Mui-selected {
    background-color: ${WEBINAR_THEMES.WHITE};
    border: 1px solid ${({ theme }) => theme.itemsColor};
  }

  .MuiTab-root ${StyledTitle} {
    color: ${WEBINAR_THEMES.BLACK};
  }

  .MuiTab-root.Mui-selected ${StyledTitle} {
    color: ${({ theme }) => theme.itemsColor};
  }

  .Mui-selected svg path {
    fill: ${({ theme }) => theme.itemsColor};
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledBadge = styled(({ color, ...rest }) => <Badge {...rest} />)`
  &.MuiBadge-root {
    position: absolute;
    right: 12px;
    top: 9px;
  }

  & .MuiBadge-badge {
    color: ${({ color }) => color};
    background-color: ${WEBINAR_THEMES.WHITE};
    border: 1px solid ${({ color }) => color};
  }
`;

const tabs = [
  {
    id: WEBINAR_RIGHT_SIDEBAR_TABS.chat,
    title: 'CHAT',
    component: () => <Chat />,
    tabIcon: 'message-circle-01',
  },
  {
    id: WEBINAR_RIGHT_SIDEBAR_TABS.polls,
    title: 'POLLS',
    component: () => <Polls />,
    tabIcon: 'bar-chart-square-01',
  },
  {
    id: WEBINAR_RIGHT_SIDEBAR_TABS.qa,
    title: 'Q&A',
    component: () => <QA />,
    tabIcon: 'message-question-circle',
  },
  {
    id: WEBINAR_RIGHT_SIDEBAR_TABS.layouts,
    title: 'STORY BOARDS',
    component: () => <Layouts />,
    tabIcon: 'layout-alt-04',
  },
  {
    id: WEBINAR_RIGHT_SIDEBAR_TABS.resources,
    title: 'RESOURCES',
    component: () => <Resources />,
    tabIcon: 'link-03',
  },
  {
    id: WEBINAR_RIGHT_SIDEBAR_TABS.brand,
    title: 'BRAND',
    component: () => <Background />,
    tabIcon: 'palette',
  },
  {
    id: WEBINAR_RIGHT_SIDEBAR_TABS.breakoutEvents,
    title: 'BREAKOUT',
    component: () => <BreakoutSessions />,
    tabIcon: 'dataflow-04',
  },
  {
    id: WEBINAR_RIGHT_SIDEBAR_TABS.sources,
    title: 'STREAMS',
    component: () => <Sources />,
    tabIcon: 'chrome-cast',
  },
];

function addSessionTitle(message: string, sessionTitle?: string) {
  if (sessionTitle) {
    return message + ` on "${sessionTitle}" breakout session`;
  }
  return message;
}

export const SidebarContent = () => {
  const notification = useSendNotification();
  const { data: session } = useGetCurrentSession();
  const { openRightSidebarTab, openedRightSidebarTab, chatTab } = useContext(
    OpenRightSidebarTabContext,
  );
  const showDefaultStreamTab = useRef(true);
  const { data: webinar } = useGetCurrentEvent();
  const { itemsColor } = useWebinarTheme(webinar);

  const [latestCurrentChannel, setLatestCurrentChannel] = useState<string>();
  const [options, setOptions] = useState<any>();

  //falg for breakout tab with setShowBreakoutTab function to handle it when need it it by default false
  const [showBreakoutTab, setShowBreakoutTab] = useState(false);

  // useEffect(() => {
  //   console.log(showBreakoutTab);
  //   setShowBreakoutTab((prev) => !prev);
  // }, []);

  const { totalUnread, setCurrentChannel, currentChannel } = useChatContext();

  const isOrganizer = useIsOrganizerOfCurrentWebinar();
  const isSpeaker = useIsSpeakerOfCurrentWebinar();

  const disableTabs = webinar.isCompleted && !isOrganizer;

  const openPollsTab = () => {
    openRightSidebarTab(0);
    chatTab.openChatTab(EngageTabs.findIndex((tab) => tab.id === 'polls'));
  };

  const pollsParams = usePollParams();
  usePollsPusherEvents(
    webinar._id,
    {
      onPollPublish(poll, sessionTitle) {
        if (session?._id === poll.session) {
          notification.send({
            title: 'New poll',
            message: addSessionTitle(`"${poll.label}" was just added`, sessionTitle),
            onClick: openPollsTab,
          });
        }
      },
      onPollAcceptingAnswers(poll, sessionTitle) {
        if (session?._id === poll.session) {
          notification.send({
            title: 'Poll accepting answers',
            message: addSessionTitle(`"${poll.label}" is now accepting answers`, sessionTitle),
            onClick: openPollsTab,
          });
        }
      },
      onPollStopAcceptingAnswers(poll, sessionTitle) {
        if (session?._id === poll.session) {
          notification.send({
            title: 'Poll stopped accepting answers',
            message: addSessionTitle(`"${poll.label}" has stopped accepting answers`, sessionTitle),
            onClick: openPollsTab,
          });
        }
      },
      onPollUpdated(poll, sessionTitle) {
        if (session?._id === poll.session) {
          notification.send({
            title: 'Poll updated',
            message: addSessionTitle(`"${poll.label}" updated`, sessionTitle),
            onClick: openPollsTab,
          });
        }
      },
    },
    pollsParams,
  );

  const openQATab = () => {
    openRightSidebarTab(0);
    chatTab.openChatTab(EngageTabs.findIndex((tab) => tab.id === 'q&a'));
  };

  const questionParams = useQuestionParams();
  useQuestionsPusherEvents(
    webinar._id,
    {
      onNonApprovedQuestionAdded(question, sessionTitle) {
        notification.send({
          title: 'New question',
          message: addSessionTitle(
            `"${question.label}" was just added. Please approve or decline`,
            sessionTitle,
          ),
          onClick: openQATab,
        });
      },

      onApprovedQuestion(question, sessionTitle) {
        if (session?._id === question.session) {
          notification.send({
            title: 'New question',
            message: addSessionTitle(`"${question.label}" was just added`, sessionTitle),
            onClick: openQATab,
          });
        }
      },

      onQuestionAnswered(question, sessionTitle) {
        if (session?._id === question.session) {
          notification.send({
            title: 'Question answered',
            message: addSessionTitle(`"${question.label}" marked as answered`, sessionTitle),
            onClick: openQATab,
          });
        }
      },

      onQuestionUnAnswered(question, sessionTitle) {
        if (session?._id === question.session) {
          notification.send({
            title: 'Question unanswered',
            message: addSessionTitle(`"${question.label}" marked as unanswered`, sessionTitle),
            onClick: openQATab,
          });
        }
      },
    },
    questionParams,
  );

  const openResourcesTab = () => {
    openRightSidebarTab(1);
  };

  useResourcePusherEvents(webinar._id, {
    onNewResource(resource) {
      notification.send({
        title: 'New resource',
        message: `"${resource.title}" was added as resource`,
        onClick: openResourcesTab,
      });
    },
  });

  const onTabClick = (tab: number) => {
    openRightSidebarTab(tab);
    if (tab === 0) {
      setCurrentChannel(latestCurrentChannel);
    } else {
      setLatestCurrentChannel(currentChannel);
      setCurrentChannel('');
    }
  };
  const speakersTabs = tabs.filter(
    // && tab.id !== WEBINAR_RIGHT_SIDEBAR_TABS.sources
    (tab) => tab.id !== WEBINAR_RIGHT_SIDEBAR_TABS.brand && tab.id !== WEBINAR_RIGHT_SIDEBAR_TABS.layouts ,
  );
  const viewersTabs = speakersTabs.filter(
    
    (tab) => tab.id !== WEBINAR_RIGHT_SIDEBAR_TABS.sources ,
  );

  const tabsWithoutBreakout = tabs.filter((tab) => tab.id !== WEBINAR_RIGHT_SIDEBAR_TABS.breakoutEvents);

  /** Polls, questions and ... to be used for hiding tabs with no contents
   */

  const { data: polls } = useGetPolls(webinar._id, pollsParams);
  const { data: questions } = useGetQuestions(webinar._id, questionParams);
  const { data: resources } = useGetResources(webinar._id);

  /***********************************/
  useEffect(() => {
    if (!viewersTabs || !tabs) return;
    let selectedTabs;
    if (!isOrganizer) {
      if (isSpeaker){
        selectedTabs=speakersTabs
      } else{
        selectedTabs = viewersTabs;
      }
    }
    else{
        selectedTabs = tabs;
    }
    if (!showBreakoutTab) {
      selectedTabs= selectedTabs.filter((tab) => tab.id !== WEBINAR_RIGHT_SIDEBAR_TABS.breakoutEvents);
    }
    selectedTabs = selectedTabs.filter((tab) => {
      if (isOrganizer) return true;
      switch (tab.id) {
        case WEBINAR_RIGHT_SIDEBAR_TABS.polls:
          return polls && polls.length > 0;
        case WEBINAR_RIGHT_SIDEBAR_TABS.qa:
          return questions && questions.length > 0;
        case WEBINAR_RIGHT_SIDEBAR_TABS.resources:
          return resources && resources.length > 0;
        default:
          return true;
      }
    });

    selectedTabs = selectedTabs.map((tab) => {
      if (tab.id === WEBINAR_RIGHT_SIDEBAR_TABS.chat) {
        return {
          ...tab,
          icon: () => (
            <StyledBadge badgeContent={totalUnread} color={itemsColor} overlap="circular" />
          ),
        };
      }
      return tab;
    });
    const isStreamsTabIndex = selectedTabs.findIndex(x=>x.id === WEBINAR_RIGHT_SIDEBAR_TABS.sources);
    if(isStreamsTabIndex > -1 && showDefaultStreamTab.current){
      onTabClick(isStreamsTabIndex);      
      showDefaultStreamTab.current = false;
    }
    setOptions(selectedTabs);
  }, [polls, questions, resources]);

  if (!options) {
    return null;
  }
  return (
    options && (
      <StyledTabs
        options={options}
        value={openedRightSidebarTab}
        disabled={disableTabs}
        handleChange={(e, value) => onTabClick(value)}
      />
    )
  );
};
