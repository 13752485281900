import styled from 'styled-components';
import { Flex, WEBINAR_THEMES, hexToRGB } from 'shared';
import { IconButton, Spinner } from 'components';

const StyledForm = styled.form`
  display: flex;
  align-items: center;
  min-height: 90px;
  position: relative;
  font-size: 12px;
  padding: 20px 0;

  & textarea {
    font-weight: 600;
    font-size: 12px;
    color: ${WEBINAR_THEMES.DARK};
    width: 95%;
    position: relative;
    z-index: 0;
    outline: none;
    resize: none;
    padding: 0;
    border: 1px solid ${WEBINAR_THEMES.LIGHTER_GREY};
    border-radius: 12px;
    top: 2px;
    line-height: 1.3;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &:focus {
      border-color: ${({ theme }) => hexToRGB(theme.additionalColors['primaryColor'])};
      outline: 0;
      z-index: 0;
      box-shadow: 0 0 0 0.1rem
        ${({ theme }) => hexToRGB(theme.additionalColors['primaryColor'], '25%')};
    }
  }

  & textarea::-webkit-scrollbar {
    display: none;
  }

  & textarea::placeholder {
    color: ${WEBINAR_THEMES.LIGHT_GREY};
  }

  & .MuiIconButton-root {
    padding: 0px;
  }
`;

const StyledSendWrapper = styled.div`
  ${Flex.fullCenter};
  width: 31px;
  height: 31px;
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledSendBtn = styled(({ color, ...rest }) => <IconButton {...rest} />)<{
  children: JSX.Element;
  type?: string;
  color?: string;
}>`
  &.MuiIconButton-root {
    width: 30px;
    height: 30px;
    background-color: ${({ theme }) => theme};
    color: ${({ color }) => color};
    ${Flex.fullCenter};
  }

  & .chat-send-icon {
    width: 13px;
    margin-left: -2px;
    margin-top: 1px;
  }

  &.MuiIconButton-root.Mui-disabled {
    background-color: ${WEBINAR_THEMES.GREY};
    color: ${WEBINAR_THEMES.WHITE};
  }

  &.MuiIconButton-root:hover {
    background-color: ${({ theme }) => theme};
  }
`;

const StyledSpinner = styled(Spinner)`
  &.MuiCircularProgress-colorPrimary {
    color: ${WEBINAR_THEMES.BLUE};
  }
`;

const mentionStyle = {
  display: 'inline',
  position: 'relative',
  zIndex: '2',
  borderRadius: '20px',
  boxSizing: 'border-box',
  wordBreak: 'break-word',
  padding: '1px',
  margin: '-3px 0 0 2px',
  whiteSpace: 'pre',
};

export { StyledForm, StyledSendBtn, StyledSpinner, StyledSendWrapper, mentionStyle };
