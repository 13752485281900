class FetchError extends Error {
  res: Response;
  url: string;
  method: string;
  constructor(method: string, res: Response, text: string) {
    super(res.status + ' | ' + text);
    this.url = res.url;
    this.method = method;
    this.res = res;
  }
}

class UnauthorizedError extends FetchError {
  constructor(method: string, res: Response, text: string) {
    super(method, res, text);
  }
}

export type QueryParams = Record<string, string | number | boolean | string[] | number[]>;

async function fetchThrowOnBadResponse(input: RequestInfo | URL, init?: RequestInit) {
  const res = await fetch(input as string, init);
  if (!res.ok) {
    const method = init?.method ?? 'GET';
    if (res.status === 401) {
      throw new UnauthorizedError(method, res, 'Unauthorized');
    }
    const text = await res.text();
    throw new FetchError(method, res, text);
  }
  return res;
}

class BaseClient {
  baseURL: string;
  baseHeaders: Record<string, string>;
  jsonHeaders: Record<string, string>;

  async callFetch(input: RequestInfo | URL, init?: RequestInit) {
    try {
      const res = await fetchThrowOnBadResponse(input, {
        ...init,
        credentials: 'include',
      });
      if (res.status !== 204 && res.headers.get('content-type')?.includes('application/json')) {
        return await res.json();
      }
      return await res.text();
    } catch (err) {
      if (err instanceof UnauthorizedError) {
        //@ts-expect-error 2339
        if (this.handleUnauthorized) {
          //@ts-expect-error 2339
          this.handleUnauthorized(err.res);
        }
      }
      throw err;
    }
  }

  async get<T = unknown>(path: string, queryParams?: QueryParams): Promise<T> {
    const url = new URL(`.${path}`, this.baseURL);
    if (queryParams) {
      const searchParams = new URLSearchParams(queryParams as Record<string, string>);
      url.search = searchParams.toString();
    }
    return await this.callFetch(url, {
      headers: this.baseHeaders,
    });
  }

  async post<T = unknown>(
    path: string,
    payload: Record<string, any>,
    queryParams?: QueryParams,
  ): Promise<T> {
    const url = new URL(`.${path}`, this.baseURL);
    if (queryParams) {
      const searchParams = new URLSearchParams(queryParams as Record<string, string>);
      url.search = searchParams.toString();
    }
    const headers = payload instanceof FormData ? this.baseHeaders : this.jsonHeaders;
    const body = payload instanceof FormData ? payload : JSON.stringify(payload);
    return await this.callFetch(url, {
      method: 'POST',
      headers,
      body,
    });
  }

  async patch<T = unknown>(path, payload): Promise<T> {
    const url = new URL(`.${path}`, this.baseURL);
    const headers = payload instanceof FormData ? this.baseHeaders : this.jsonHeaders;
    const body = payload instanceof FormData ? payload : JSON.stringify(payload);
    return await this.callFetch(url, {
      method: 'PATCH',
      headers,
      body,
    });
  }

  async put<T = unknown>(path: string, payload: any): Promise<T> {
    const url = new URL(`.${path}`, this.baseURL);
    const headers = payload instanceof FormData ? this.baseHeaders : this.jsonHeaders;
    const body = payload instanceof FormData ? payload : JSON.stringify(payload);
    return await this.callFetch(url, {
      method: 'PUT',
      headers,
      body,
    });
  }

  async delete<T = unknown>(path: string, payload?: any): Promise<T> {
    const url = new URL(`.${path}`, this.baseURL);
    const params: RequestInit = {
      method: 'DELETE',
      headers: this.jsonHeaders,
    };
    if (payload) {
      params.body = JSON.stringify(payload);
    }
    return await this.callFetch(url, params);
  }

  async rawFetch(
    path: string,
    init: RequestInit = {
      method: 'GET',
    },
  ) {
    const url = new URL(`.${path}`, this.baseURL);
    init.headers = this.baseHeaders;
    return await fetch(url.toString(), init);
  }
}

type UnauthorizedListener = (res: Response) => void;

export class ApiClient extends BaseClient {
  static unauthorizedListeners: Array<UnauthorizedListener> = [];

  constructor(token?: string) {
    super();
    this.baseURL = `${process.env.REACT_APP_API_URL}/`;
    this.baseHeaders = {};
    if (token) {
      this.baseHeaders['x-access-token'] = token;
    }
    this.jsonHeaders = {
      ...this.baseHeaders,
      'Content-Type': 'application/json',
    };
  }

  static addUnauthorizedListener(listener: UnauthorizedListener) {
    ApiClient.unauthorizedListeners.push(listener);
  }

  static removeUnauthorizedListener(listener: UnauthorizedListener) {
    ApiClient.unauthorizedListeners = ApiClient.unauthorizedListeners.filter((l) => l !== listener);
  }

  handleUnauthorized(res: Response) {
    for (const listener of ApiClient.unauthorizedListeners) {
      listener(res);
    }
  }
}
