import { toast, ToastOptions } from 'react-toastify';
import useSound from 'shared/hooks/useSound';
import juntosSound from 'assets/sounds/juntos.mp3';
import { useState } from 'react';
import { storageService } from '../services';
import styled from 'styled-components';
import { WEBINAR_THEMES } from 'shared';
import { useGetCurrentEvent } from './api/event';
import { useWebinarTheme, WebinarTheme } from './webinar';

type Kind = 'success' | 'error' | 'info';

export interface NotificationData {
  kind?: Kind;
  title?: string;
  message: string;
  onClick?: () => void;
}

const ToastHeader = styled.div<{ color?: string }>`
  color: ${({ color = WEBINAR_THEMES.BLACK }) => color};
  font-weight: bold;
  margin-bottom: 8px;
`;

const ToastBody = styled.div`
  font-weight: normal;
`;

const Notification = ({
  title,
  message,
  headerColor,
  onClick,
}: {
  title: string;
  message: string;
  headerColor?: string;
  onClick?: () => void;
}) => {
  return (
    <div onClick={onClick}>
      <ToastHeader color={headerColor}>{title}</ToastHeader>
      <ToastBody>{message}</ToastBody>
    </div>
  );
};

function getHeaderColorForKind(kind: Kind, theme: WebinarTheme) {
  if (kind === 'error') {
    return WEBINAR_THEMES.RED;
  }
  if (kind === 'success') {
    return WEBINAR_THEMES.GREEN;
  }
  return theme.color;
}

export const useSendNotification = () => {
  const { data: webinar } = useGetCurrentEvent();
  const theme = useWebinarTheme(webinar);

  const sound = useSound(juntosSound);

  const send = (data: NotificationData) => {
    const disabled = !!JSON.parse(storageService.getItem('notificationsDisabled'));
    const params: ToastOptions = {
      type: data.kind,
      data: data,
    };
    if (disabled) {
      params.style = {
        display: 'none',
      };
    }
    toast(
      <Notification
        headerColor={getHeaderColorForKind(data.kind, theme)}
        title={data.title}
        message={data.message}
        onClick={data.onClick}
      />,
      params,
    );
    if (!disabled) {
      sound.play();
    }
  };
  return {
    send,
    error: (params: Partial<Omit<NotificationData, 'kind'>>) => {
      send({
        title: 'Error!',
        message: 'Error has occurred',
        kind: 'error',
        ...params,
      });
    },
    success: (params: Partial<Omit<NotificationData, 'kind'>>) => {
      send({
        title: 'Success!',
        message: 'Operation has completed successfully',
        kind: 'success',
        ...params,
      });
    },
  };
};
