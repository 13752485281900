// // import React from 'react';

import styled from 'styled-components';
import './OverlayText.css';
const StyledColoredRectangle = styled.div<{ color: any }>`

  
  background-color  : ${({ color }) => color};
  
  /* border-radius: 3px; */
  /* width: 150px;  */
  height: 30px;
  position: relative;
}`;

const OverlayText = ({
  name,
  yPosition,
  animation,
  position = null,
  animationDuration = 10,
  color,
  rectangleColor = null,
  icon = null,
  align = 'right',
  triggerAnimation = true,
  streamWidth = null,
}) => {
  const positionStyle = {
    ...(streamWidth <= 200 && { top: 0 }),
    ...(streamWidth > 200 && { bottom: `${yPosition * 100}%` }),
  };
  const overlayAnimationTime = {
    animationDuration: `${animationDuration}s`,
  };

  const nameStyle = {
    color: color,
  };
  // const [doAnimation, setStartAnimation] = useState(false);
  // useEffect(() => {
  //   if (triggerAnimation) {
  //     setStartAnimation(true);
  //     const timer = setTimeout(() => {
  //       setStartAnimation(false);
  //       triggerAnimation = false;
  //     }, animationDuration*1000);

  //     return () => clearTimeout(timer);
  //   }
  // }, [triggerAnimation]);
  const animationClass = triggerAnimation ? animation : `${animation}-out`;

  return (
    <>
      <div
        className={`${
          streamWidth && streamWidth <= 200 ? 'overlay-container-small' : 'overlay-container w-auto'
        }`}
        style={positionStyle}
      >
        <div
          className={`${
            streamWidth && streamWidth <= 200
              ? 'overlay-background-small'
              : 'overlay-background pl-0 pr-5'
          } ${animationClass} py-2`}
          style={overlayAnimationTime}
        >
          {icon && <img src={icon} alt="Icon" className="overlay-icon" />}
          <StyledColoredRectangle
            color={rectangleColor ?? '#4082f9'}
            className="colored-rectangle w-2 h-7 rounded-r-sm  	"
          ></StyledColoredRectangle>
          <div
            className={`${
              streamWidth && streamWidth <= 200 ? 'overlay-text-name-small' : 'overlay-text-name'
            } ${align === 'right' ? 'overlay-text-right' : 'overlay-text-left'} ml-1  `}
          >
            <div
              className={`${
                streamWidth && streamWidth <= 200
                  ? 'overlay-text-position-small'
                  : 'overlay-text-position'
              }`}
              style={nameStyle}
            >
              {name}
            </div>
            {position && (
              <div
                className={`${
                  streamWidth && streamWidth <= 200
                    ? 'overlay-text-position-small'
                    : 'overlay-text-position'
                }`}
              >
                {position}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default OverlayText;
