import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { layoutPresetValidationSchema } from './formSchema';
import { VideoTypes } from 'shared/configs/videoTypes';
import { Input, Select } from 'components';
import { IconButtonBase } from 'components/ui/IconButtonBase/IconButtonBase';
import { ThemedSwitch } from 'components/ui/ThemedSwitch';
import { StyledContainer, StyledForm } from './LayoutForm.styled';
import { useGetCurrentEvent } from 'lib/api/event';
import { useWebinarTheme } from 'lib/webinar';
import { useGetCurrentSession } from 'lib/api/session';
import { useCreateLayoutPreset, useUpdateLayoutPreset } from 'lib/api/layoutPreset';
import { useSendNotification } from 'lib/notifications';
import Button from 'components/ui/Button';
import { StyledList } from '../layouts.styled';
import { WEBINAR_THEMES } from 'shared';
import styled from 'styled-components';
import { LayoutsOptions } from 'Webinar/LiveStream/CustomLayouts/layoutOptions';
import { FormControl } from '@material-ui/core';
import { LayoutPreset, LayoutPresetSource } from 'models/LayoutPreset';
import { useGetWebinarSpeakers } from 'lib/api/speakers';
import { StageLayoutMap } from 'models/Stage';
export const StyledItem = styled.div<{ selected: boolean; disabled: boolean }>`
  height: 38px;
  width: 54px;
  color: ${({ selected, theme }) =>
    selected ? theme.additionalColors.primaryColor : WEBINAR_THEMES.GREY};
  border-radius: 8px;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  transition: color 0.2s ease;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)}

  &:hover {
    color: ${({ theme }) => {
      return theme.additionalColors.primaryColor;
    }};
  }
`;

export const LayoutPresetForm = ({
  webinarId,
  layoutPreset,
  onRequestClose,
  layoutPresetsParams,
}) => {
  let emptySource: LayoutPresetSource = { speakerId: null, userId: null, videoType: null };
  const notification = useSendNotification();
  const { data: webinar } = useGetCurrentEvent();
  const { data: session } = useGetCurrentSession();
  const theme = useWebinarTheme(webinar);
  const { mutateAsync: createLayoutPreset, isLoading: isLoadingCreateLayoutPreset } =
    useCreateLayoutPreset(webinarId, layoutPresetsParams);
  const { mutateAsync: updateLayoutPreset, isLoading: isLoadingUpdateLayoutPreset } =
    useUpdateLayoutPreset(webinarId, layoutPreset?._id, layoutPresetsParams);
  const isLoading = isLoadingCreateLayoutPreset || isLoadingUpdateLayoutPreset;
  const [selectedPredefinedLayout, setSelectedPredefinedLayout] = useState<String>('');
  const [layoutPresetValue, setLayoutPresetValue] = useState<Partial<LayoutPreset>>({
    ...layoutPreset,
  });
  const [speakerOptions, setSpeakerOptions] = useState<Array<any>>(null);
  const [invalidSources, setInvalidSources] = useState<boolean>(true);

  // Detect duplicated sources (Same user & type)
  useEffect(() => {
    let valid = true;
    if (layoutPresetValue && layoutPresetValue.sources) {
      for (let i = 0; i < layoutPresetValue.sources.length; i++) {
        const e1 = layoutPresetValue.sources[i];
        for (let j = i + 1; j < layoutPresetValue.sources.length; j++) {
          const e2 = layoutPresetValue.sources[j];
          if (e1.userId == e2.userId && e1.videoType == e2.videoType) valid = false;
          break;
        }
      }
    }
    setInvalidSources(!valid);
  }, [layoutPresetValue]);

  const { data: allSpeakers } = useGetWebinarSpeakers(webinarId);
  const handleSubmit = async (e) => {
    e.preventDefault();
    let createOrUpdatedLayoutPreset = {
      ...layoutPresetValue,
      ...formik.values,
      type: selectedPredefinedLayout,
      event: webinarId,
      // session: session._id,
    };
    if (layoutPreset?._id) {
      await updateLayoutPreset(createOrUpdatedLayoutPreset);
    } else {
      await createLayoutPreset(createOrUpdatedLayoutPreset);
    }

    onRequestClose();
  };
  useEffect(() => {
    if (!allSpeakers) {
      setSpeakerOptions(null);
      return;
    }
    setSpeakerOptions(
      allSpeakers.map((speaker) => {
        return {
          label: speaker.firstName + ' ' + speaker.lastName,
          value: speaker._id,
        };
      }),
    );
  }, [allSpeakers]);

  const formik = useFormik({
    initialValues: {
      label: '',
      type: '',
      _id: null,
      isPublished: false,
    },
    validationSchema: layoutPresetValidationSchema,
    onSubmit: async (values) => {
      const newLayoutPresetCreateData = {
        type: values.type,
        label: values.label,
        isPublished: values.isPublished,
        session: session?._id,
      };
      if (session) {
        newLayoutPresetCreateData.session = session?._id;
      }
      try {
        if (layoutPresetValue) {
          await updateLayoutPreset(newLayoutPresetCreateData);
        } else {
          await createLayoutPreset(newLayoutPresetCreateData);
        }
        onRequestClose();
      } catch (e) {
        notification.error({
          message: 'Error creating new layoutPreset',
        });
      }
    },
  });

  useEffect(() => {
    const layoutOption = LayoutsOptions.find((elem) => {
      return elem.type == selectedPredefinedLayout;
    });
    let sources = layoutPresetValue?.sources;
    if (layoutOption) {
      let requiredLength = layoutOption.numberOfElements;
      if (sources) {
        let prevLength = sources.length;
        if (prevLength > requiredLength) {
          const toDeleteCount = prevLength - requiredLength;
          const toDeleteStartIndex = prevLength - toDeleteCount;
          sources.splice(toDeleteStartIndex, toDeleteCount);
        } else if (prevLength < requiredLength) {
          sources = sources.concat(
            Array(requiredLength - prevLength)
              .fill()
              .map(() => ({ ...emptySource })),
          );
        }
      }
    } else {
      sources = [];
    }
    setLayoutPresetValue({ ...layoutPresetValue, sources: sources });
  }, [selectedPredefinedLayout]);

  useEffect(() => {
    if (layoutPreset) {
      setLayoutPresetValue(layoutPreset);

      setSelectedPredefinedLayout(layoutPreset.type);
      for (const key in formik.values) {
        if (Object.prototype.hasOwnProperty.call(layoutPreset, key)) {
          formik.values[key] = layoutPreset[key];
        }
      }
      setSelectedPredefinedLayout(formik.values.type);
    }
  }, [layoutPreset]);

  const handleSpeakerTextChange = (index, value, attributeName) => {
    if (layoutPresetValue.sources) {
      let newSources = [...layoutPresetValue.sources];
      newSources[index][attributeName] = value;
    }
  };

  const handleSelectChange = (index, value, attributeName) => {
    if (layoutPresetValue.sources) {
      let newSources = [...layoutPresetValue.sources];
      newSources[index][attributeName] = value;
      if (attributeName == 'speakerId') {
        const speaker = allSpeakers.find((elem) => elem._id == value);
        if (speaker) {
          if (typeof speaker.user === 'string') {
            newSources[index]['userId'] = speaker.user;
          } else {
            newSources[index]['userId'] = speaker.user._id;
            newSources[index]['firstName'] = speaker.firstName;
            newSources[index]['lastName'] = speaker.lastName;
            newSources[index]['title'] = speaker.title;
          }
        }
      }

      setLayoutPresetValue({ ...layoutPresetValue, sources: newSources });
    }
  };
  const getDefaultValue = (element) => {
    if (speakerOptions && element) {
      let speakerOption = speakerOptions.find((option) => option.value === element.speakerId);
      return speakerOption;
    }
    return '';
  };

  const getSpeakerPrefillAttribute = (element, attributeName) => {
    if (allSpeakers && element) {
      let speaker = allSpeakers.find((speaker) => speaker._id === element.speakerId);
      if (speaker) return speaker[attributeName];
    }
    return '';
  };

  return (
    <StyledContainer>
      <div>
        <IconButtonBase size="small" onClick={onRequestClose}>
          <ArrowBackIcon />
        </IconButtonBase>
      </div>
      <div className="mb-4">Layout Selections:</div>
      <div>
        <StyledForm onSubmit={handleSubmit}>
          <div>Select Layout:</div>
          <StyledList className="!grid !grid-cols-12 xl:!grid-cols-10 gap-1 lg:gap-4 justify-items-center w-full">
            {LayoutsOptions.map((l, index) => (
              <StyledItem
                className="!col-span-4 xl:!col-span-2"
                key={index}
                tabIndex={0}
                title={StageLayoutMap[l.type.toString()] ?? l.type.toString()}
                selected={l.type === selectedPredefinedLayout}
                onClick={() => !isLoading && setSelectedPredefinedLayout(l.type)}
                disabled={isLoading}
              >
                {l.comp({})}
              </StyledItem>
            ))}
          </StyledList>
          <div className="mt-4">Select Speakers & Sources:</div>
          {speakerOptions &&
            layoutPresetValue &&
            layoutPresetValue.sources &&
            layoutPresetValue.sources.map(
              (element, index) =>
                speakerOptions &&
                element && (
                  <div className="border border-[#575757] pd px-2	pt-0 py-2 rounded-lg">
                    <div className="flex justify-center mt-4">
                      <div className="text-gray-600  text-2xl inline-block">{index + 1}</div>
                    </div>

                    <div className="flex gap-4 w-full">
                      <div className="flex sm:!flex-row md:flex-col lg:flex-row gap-3 w-full">
                        <FormControl
                          required
                          variant="outlined"
                          className="sm:!w-full md:w-full lg:w-1/2 !max-w-[200px]"
                        >
                          <Select
                            placeholder="Speaker"
                            theme={theme}
                            value={getDefaultValue(element)}
                            options={speakerOptions}
                            onChange={(option: { label: string; value: string }) =>
                              handleSelectChange(index, option.value, 'speakerId')
                            }
                          />
                        </FormControl>
                        <FormControl
                          required
                          variant="outlined"
                          className="sm:!w-full md:w-full lg:w-1/2"
                        >
                          <Select
                            placeholder="Source Type"
                            theme={theme}
                            value={VideoTypes.find((v) => {
                              return v.value == element.videoType;
                            })}
                            options={VideoTypes}
                            onChange={(option: { label: string; value: string }) =>
                              handleSelectChange(index, option.value, 'videoType')
                            }
                          />
                        </FormControl>
                      </div>
                    </div>

                    <div className="flex gap-4">
                      <div className="flex sm:!flex-row md:flex-col lg:flex-row gap-3 w-full">
                        <FormControl variant="outlined" className="sm:!w-full md:w-full lg:w-1/2">
                          <Input
                            label="Title"
                            hideLabel={true}
                            name="title"
                            type="text"
                            defaultValue={
                              element.title ?? getSpeakerPrefillAttribute(element, 'title')
                            }
                            placeholder="Title"
                            variant="primaryColor"
                            onChange={(event) =>
                              handleSpeakerTextChange(index, event.target.value, 'title')
                            }
                            disabled={isLoading}
                          />
                        </FormControl>

                        <FormControl variant="outlined" className="sm:!w-full md:w-full lg:w-1/2">
                          <Input
                            hideLabel={true}
                            label="First Name"
                            name="firstname"
                            defaultValue={
                              element.firstName ?? getSpeakerPrefillAttribute(element, 'firstName')
                            }
                            type="text"
                            placeholder="First Name"
                            variant="primaryColor"
                            onChange={(event) =>
                              handleSpeakerTextChange(index, event.target.value, 'firstName')
                            }
                            disabled={isLoading}
                          />
                        </FormControl>

                        <FormControl variant="outlined" className="sm:!w-full md:w-full lg:w-1/2">
                          <Input
                            hideLabel={true}
                            label="Last Name"
                            name="lastname"
                            defaultValue={
                              element.lastName ?? getSpeakerPrefillAttribute(element, 'lastName')
                            }
                            type="text"
                            placeholder="Last Name"
                            variant="primaryColor"
                            onChange={(event) =>
                              handleSpeakerTextChange(index, event.target.value, 'lastName')
                            }
                            disabled={isLoading}
                          />
                        </FormControl>
                      </div>
                    </div>
                    <div className="text-xs	text-center	pt-1	">
                      If the first and last names are empty, the overlay won't be shown
                    </div>
                  </div>
                ),
            )}
          <Input
            label="Label"
            name="label"
            type="text"
            required
            placeholder="Enter layout name"
            variant="primaryColor"
            value={formik.values.label}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.label && Boolean(formik.errors.label)}
            helperText={formik.touched.label && formik.errors.label}
            disabled={isLoading}
          />
          {invalidSources && <div className="text-xs	pt-1	text-red-700">
            ** Cannot add the same source twice!
          </div>}
          
          <Button
            type="submit"
            loading={isLoading}
            disabled={isLoading || invalidSources}
            className="!mb-5"
          >
            {layoutPreset?._id ? 'Update' : 'Create'}
          </Button>
        </StyledForm>
      </div>
    </StyledContainer>
  );
};
