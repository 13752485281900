import { useGetCurrentEvent } from 'lib/api/event';
import { Grow, Skeleton } from 'components';

import {
  StyledContainer,
  StyledList,
  StyledSkeletons,
  StyledContent,
  StyledSkeletonCircle,
  StyledSkeletonWrapper,
  StyledWrapper,
  StyledCustomLayouts,
  StyledCustomLayoutSection,
} from './sources.styled';
// ! TODO FIX THE FOLLOWING IMPORT to be in a unified place, or copy the required function here.
import { useGetLayoutPresets, useLayoutPresetParams } from 'lib/api/layoutPreset';
import { useIsOrganizerOfCurrentWebinar } from 'lib/webinar';

export const Sources = () => {
  const { data: webinar } = useGetCurrentEvent();
  const isOrganizer = useIsOrganizerOfCurrentWebinar();
  const webinarId = webinar?._id;

  const layoutPresetsParams = useLayoutPresetParams();

  /******************************************************/
  const isLoading = isOrganizer ? (useGetLayoutPresets(webinarId, layoutPresetsParams))?.isLoading : false;

  if (isLoading) {
    <StyledContainer>
      return (
      <Grow in={true} timeout={300}>
        <StyledSkeletonWrapper>
          {Array(3)
            .fill(1)
            .map((_, ind) => (
              <StyledSkeletons key={ind}>
                <StyledContent>
                  <StyledSkeletonCircle variant="circle" />
                  <div style={{ width: '100%' }}>
                    <Skeleton
                      variant="rect"
                      height={10}
                      width="30%"
                      style={{ borderRadius: '10px', marginBottom: '5px' }}
                    />
                    <Skeleton
                      variant="rect"
                      height={10}
                      width="20%"
                      style={{ borderRadius: '10px' }}
                    />
                  </div>
                </StyledContent>
                <StyledWrapper>
                  <Skeleton
                    variant="rect"
                    height={15}
                    width="20%"
                    style={{ borderRadius: '10px' }}
                  />
                  <Skeleton
                    variant="rect"
                    height={15}
                    width="20%"
                    style={{ borderRadius: '10px' }}
                  />
                </StyledWrapper>
                <Skeleton variant="rect" height={25} width="100%" style={{ borderRadius: '4px' }} />
              </StyledSkeletons>
            ))}
        </StyledSkeletonWrapper>
      </Grow>
      );
    </StyledContainer>;
  }
  return (
    <>
      <StyledContainer>
        <StyledList>
          <div>
            <div>
              <div id="right-side-bar-moderation-div"></div>
            </div>
          </div>
        </StyledList>
      </StyledContainer>
    </>
  );
};
