import Pubnub from 'pubnub';
import {
  InfiniteData,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useGetCurrentUser } from './user';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useApiClient } from '.';
import { useEventUrlKey } from 'shared';
import { ChatRoom } from 'models/ChatRoom';
import { useSendNotification } from 'lib/notifications';
import { EngageTabs } from 'Webinar/RightSidebar/Engage/Engage';
import { OpenRightSidebarTabContext } from 'contexts';

export interface AppMessage {
  timetoken: string;
  meta: { uuid: string; firstName: string; lastName: string; profileImageUrl: string };
  text: string;
  isDeleted?: boolean;
}

export interface ChannelMetadata {
  pinnedMessage?: string;
}

const useGenerateAuthToken = () => {
  const apiClient = useApiClient();
  const eventUrlKey = useEventUrlKey();
  return useMutation(async () => {
    const { data } = await apiClient.post<{ data: { authKey: string } }>(
      `/events/${eventUrlKey}/chat/auth-key`,
      {},
    );
    return data.authKey;
  });
};

const usePubNub = () => {
  const { data: user } = useGetCurrentUser();
  const { mutateAsync: generateAuthKey, data: authKey, isLoading } = useGenerateAuthToken();

  useEffect(() => {
    if (!authKey && !isLoading) {
      generateAuthKey();
    }
  }, [authKey, isLoading, generateAuthKey]);

  const pubnub = useMemo(() => {
    if (!user || !authKey) return null;
    const pubnub = new Pubnub({
      subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY,
      publishKey: process.env.REACT_APP_PUBNUB_PUBLISH_KEY,
      uuid: user._id,
      authKey: authKey,
    });
    return pubnub;
  }, [user, authKey]);

  useEffect(() => {
    if (pubnub) {
      pubnub.setFilterExpression(`uuid != '${user._id}'`);
    }
  }, [pubnub, user]);

  return pubnub;
};

const chatChannelsKey = (eventUrlKey: string) => ['chat_channels', eventUrlKey];

export const useChatRooms = (eventUrlKey: string) => {
  const apiClient = useApiClient();
  return useQuery(chatChannelsKey(eventUrlKey), async () => {
    const { data } = await apiClient.get<{ data: { rooms: ChatRoom[] } }>(
      `/events/${eventUrlKey}/chat/rooms`,
    );
    return data.rooms;
  });
};

const getKeyForChannel = (channel: string) => ['messages', channel];
const getKeyForChannelMetadata = (channel: string) => ['messages', channel, 'metadata'];
const getKeyForUnreadMessages = (channels: string[], timetokens: string[]) => [
  'unread_messages_count',
  channels,
  timetokens,
];
const getKeyForChannelPinnedMessage = (channel: string, timetoken: string) => [
  'messages',
  channel,
  'pinnedMessage',
  timetoken,
];

function fromDataToMessage(data: Pubnub.FetchMessagesResponse['channels'][string][0]) {
  return {
    timetoken: data.timetoken,
    text: data.message.text,
    meta: data.meta,
    isDeleted: !!data.actions?.delete,
  } as AppMessage;
}

const useChannelMetadata = (pubnub: Pubnub, currentChannel: string) => {
  const queryClient = useQueryClient();

  const currentChannelMetadata = useQuery<ChannelMetadata>(
    getKeyForChannelMetadata(currentChannel),
    async () => {
      try {
        const response = await pubnub.objects.getChannelMetadata({
          channel: currentChannel,
          include: { customFields: true },
        });
        return response.data.custom as ChannelMetadata;
      } catch (e) {
        if (e.status.statusCode === 404) {
          return {};
        }
        throw e;
      }
    },
    { enabled: !!pubnub && !!currentChannel },
  );

  const currentChannelPinnedMessage = useQuery<AppMessage>(
    getKeyForChannelPinnedMessage(currentChannel, currentChannelMetadata.data?.pinnedMessage),
    async () => {
      try {
        const start = BigInt(currentChannelMetadata.data.pinnedMessage) + 1n;
        const response = await pubnub.fetchMessages({
          channels: [currentChannel],
          start: start.toString(),
          end: currentChannelMetadata.data.pinnedMessage,
          count: 1,
          includeMeta: true,
          includeMessageActions: true,
        });
        if (!response.channels?.[currentChannel]) return null;
        return response.channels[currentChannel].map(fromDataToMessage)[0];
      } catch (e) {
        if (e.statusCode === 404) {
          return null;
        }
        throw e;
      }
    },
    {
      enabled: !!currentChannelMetadata.data?.pinnedMessage,
    },
  );

  const updateCurrentChannelMetadataInQuery = (metadata: ChannelMetadata) => {
    queryClient.setQueryData(getKeyForChannelMetadata(currentChannel), metadata);
  };

  const setCurrentChannelMetadata = useMutation(
    async (metadata: ChannelMetadata) => {
      await pubnub.objects.setChannelMetadata({
        channel: currentChannel,
        //@ts-ignore
        data: { custom: metadata },
        include: { customFields: true },
      });
      return metadata;
    },
    {
      onSuccess: (metadata) => {
        updateCurrentChannelMetadataInQuery(metadata);
      },
    },
  );

  return {
    currentChannelMetadata: currentChannelMetadata.data,
    currentChannelPinnedMessage: currentChannelPinnedMessage.data,
    setCurrentChannelMetadata: setCurrentChannelMetadata.mutateAsync,
  };
};

export const useUnreadMessageCount = (pubnub: Pubnub, chatRooms: ChatRoom[]) => {
  const eventUrlKey = useEventUrlKey();
  const { data: user } = useGetCurrentUser();
  const timeTokensCacheKey = useMemo(
    () => `timeTokensCache-${eventUrlKey}-${user._id}`,
    [eventUrlKey, user],
  );

  const channels = useMemo(() => chatRooms?.map((c) => c.channel), [chatRooms]);
  const [channelTimetokens, setChannelTimetokens] = useState<string[]>([]);

  useEffect(() => {
    if (!channels || channels.length === 0) return;
    const timeTokensCacheStr = localStorage.getItem(timeTokensCacheKey);
    let timeTokensCache: string[];
    try {
      timeTokensCache = JSON.parse(timeTokensCacheStr);
    } catch (e) {
      console.error(e);
    }
    if (!timeTokensCache || timeTokensCache.length !== channels.length) {
      timeTokensCache = channels.map(() => Date.now() + '0000');
    }
    setChannelTimetokens(timeTokensCache);
  }, [channels, timeTokensCacheKey]);

  useEffect(() => {
    try {
      localStorage.setItem(timeTokensCacheKey, JSON.stringify(channelTimetokens));
    } catch (e) {
      console.error(e);
    }
  }, [channelTimetokens, timeTokensCacheKey]);

  const unreadCounts = useQuery<Record<string, number>>(
    getKeyForUnreadMessages(channels, channelTimetokens),
    async () => {
      const response = await pubnub.messageCounts({
        channels,
        channelTimetokens,
      });
      return response.channels;
    },
    {
      enabled: !!pubnub && !!channels && channelTimetokens.length === channels.length,
    },
  );

  const totalUnread = useMemo(() => {
    if (!unreadCounts.data) return 0;
    return Object.values(unreadCounts.data).reduce((sum, num) => sum + num, 0);
  }, [unreadCounts.data]);

  const setChannelTimetoken = useCallback(
    (channel: string, timetoken: string) => {
      const index = channels.indexOf(channel);
      setChannelTimetokens((timetokens) => {
        timetokens[index] = timetoken;
        return timetokens.slice();
      });
    },
    [channels],
  );

  return {
    unreadCounts: unreadCounts.data,
    refetchUnreadCounts: unreadCounts.refetch,
    totalUnread,
    setChannelTimetoken,
  };
};

export const useChatMessages = () => {
  const eventUrlKey = useEventUrlKey();
  const notification = useSendNotification();

  const chatRooms = useChatRooms(eventUrlKey);

  const {
    openRightSidebarTab,
    chatTab: { openChatTab },
  } = useContext(OpenRightSidebarTabContext);

  const pubnub = usePubNub();
  const queryClient = useQueryClient();
  const { data: user } = useGetCurrentUser();
  const userFullName = useMemo(() => `${user.firstName} ${user.lastName}`, [user]);

  const [currentChannel, setCurrentChannel] = useState<string>();

  const unreadCounts = useUnreadMessageCount(pubnub, chatRooms.data);

  const requestFillChatInputListeners = useRef<Array<(content: string) => unknown>>([]);

  const addRequestFillChatInputListener = (listener: (content: string) => unknown) => {
    requestFillChatInputListeners.current.push(listener);
  };

  const removeRequestFillChatInputListener = (listener: (content: string) => unknown) => {
    requestFillChatInputListeners.current = requestFillChatInputListeners.current.filter(
      (l) => l !== listener,
    );
  };

  const fillChatInput = (content: string) => {
    requestFillChatInputListeners.current.forEach((listener) => listener(content));
  };

  const [typingIndicatorForChannel, setTypingIndicatorForChannel] = useState<
    Record<string, string>
  >({});

  const sendTypingSignal = useCallback(async () => {
    await pubnub.signal({
      channel: currentChannel,
      message: userFullName,
    });
  }, [pubnub, currentChannel, userFullName]);

  const appendMessageToChannelInQuery = useCallback(
    (message: AppMessage, channel: string) => {
      queryClient.setQueryData<InfiniteData<AppMessage[]>>(getKeyForChannel(channel), (data) => {
        data.pages[0].splice(0, 0, message);
        return {
          pages: data.pages.slice(),
          pageParams: data.pageParams,
        };
      });
    },
    [queryClient],
  );

  const removeMessageFromChannelInQuery = useCallback(
    (timetoken: string, channel: string) => {
      queryClient.setQueryData<InfiniteData<AppMessage[]>>(getKeyForChannel(channel), (data) => {
        const pages = data.pages?.map((page) => {
          return page.map((m) => {
            if (m.timetoken === timetoken) {
              return {
                ...m,
                isDeleted: true,
              };
            }
            return m;
          });
        });
        return {
          pages,
          pageParams: data.pageParams,
        };
      });
    },
    [queryClient],
  );

  const removeMessageFromCurrentChannel = useMutation(
    async (timetoken: string) => {
      await pubnub.addMessageAction({
        channel: currentChannel,
        action: { type: 'delete', value: 'delete' },
        messageTimetoken: timetoken,
      });

      return timetoken;
    },
    {
      onSuccess: (timetoken) => {
        removeMessageFromChannelInQuery(timetoken, currentChannel);
      },
    },
  );

  const currentUserMessageData = useMemo(
    () => ({
      uuid: user._id,
      firstName: user.firstName,
      lastName: user.lastName,
      profileImageUrl: user.profileImageUrl,
    }),
    [user],
  );

  const currentChannelMessages = useInfiniteQuery<AppMessage[]>({
    queryKey: getKeyForChannel(currentChannel),
    queryFn: async ({ pageParam = 0 }) => {
      const fetchParams: Pubnub.FetchMessagesParameters = {
        channels: [currentChannel],
        count: 25,
        includeMeta: true,
        includeMessageActions: true,
      };
      if (!pageParam) {
        fetchParams.end = 0;
      } else {
        fetchParams.start = pageParam;
      }
      const response = await pubnub.fetchMessages(fetchParams);
      if (!response.channels?.[currentChannel]) return [];
      return response.channels[currentChannel].reverse().map(fromDataToMessage);
    },
    getNextPageParam: (messages) => messages[messages.length - 1]?.timetoken,
    select: (data) => ({
      pages: data.pages.map((page) => {
        return page.filter((m) => !m.isDeleted);
      }),
      pageParams: data.pageParams,
    }),

    onSuccess: ({ pages }) => {
      if (pages?.[0]?.[0]) {
        unreadCounts.setChannelTimetoken(currentChannel, pages?.[0]?.[0].timetoken);
      }
    },
    enabled: !!pubnub && !!currentChannel,
  });

  const postNewMessage = useMutation(
    async (text: string) => {
      const response = await pubnub.publish({
        channel: currentChannel,
        message: { text },
        meta: currentUserMessageData,
        storeInHistory: true,
      });

      const message: AppMessage = {
        timetoken: response.timetoken.toString(),
        text,
        meta: currentUserMessageData,
      };
      return message;
    },
    {
      onSuccess: (message) => {
        appendMessageToChannelInQuery(message, currentChannel);
      },
    },
  );

  const typingIndicatorCleanTimeouts = useRef<Record<string, number>>({});
  const addTypingIndicatorForChannel = useCallback(
    (channel: string, typingIndicatorMessage: string) => {
      setTypingIndicatorForChannel((indicators) => {
        return { ...indicators, [channel]: typingIndicatorMessage };
      });
      if (typingIndicatorCleanTimeouts.current[channel]) {
        clearTimeout(typingIndicatorCleanTimeouts.current[channel]);
      }
      typingIndicatorCleanTimeouts.current[channel] = setTimeout(() => {
        setTypingIndicatorForChannel((indicators) => {
          return { ...indicators, [channel]: undefined };
        });
      }, 3000);
    },
    [],
  );

  const eventListeners: Pubnub.ListenerParameters = useMemo(() => {
    return {
      message(message) {
        const messageData = {
          timetoken: message.timetoken,
          meta: message.userMetadata,
          text: message.message.text,
        };
        appendMessageToChannelInQuery(messageData, message.channel);
        if (message.channel === currentChannel) {
          unreadCounts.setChannelTimetoken(currentChannel, message.timetoken);
        }
        unreadCounts.refetchUnreadCounts();
        const isUserMentioned = messageData.text.includes(`(${user._id})`);
        if (isUserMentioned) {
          const channel = chatRooms.data.find((c) => c.channel === message.channel);
          notification.send({
            title: `New mention in ${channel?.name}`,
            message: 'New message in chat',
            onClick: () => {
              setCurrentChannel(message.channel);
              openRightSidebarTab(0);
              openChatTab(EngageTabs.findIndex((tab) => tab.id === 'chat'));
            },
          });
        }
      },
      messageAction(action) {
        if (action.data.type === 'delete') {
          removeMessageFromChannelInQuery(action.data.messageTimetoken, action.channel);
        }
      },
      signal(signal) {
        if (signal.message !== userFullName) {
          addTypingIndicatorForChannel(signal.channel, signal.message);
        }
      },
    };
  }, [
    openRightSidebarTab,
    openChatTab,
    chatRooms.data,
    notification,
    user._id,
    addTypingIndicatorForChannel,
    appendMessageToChannelInQuery,
    currentChannel,
    unreadCounts,
    userFullName,
  ]);

  useEffect(() => {
    if (!pubnub) {
      return;
    }
    pubnub.addListener(eventListeners);
    return () => {
      pubnub.removeListener(eventListeners);
    };
  }, [pubnub, eventListeners]);

  useEffect(() => {
    if (!pubnub || !chatRooms.data) {
      return;
    }

    const channels = chatRooms.data.map((r) => r.channel);

    pubnub.subscribe({
      channels,
    });

    return () => {
      pubnub.unsubscribe({
        channels,
      });
    };
  }, [pubnub, chatRooms.data]);

  const channelMetadata = useChannelMetadata(pubnub, currentChannel);

  return {
    isLoadingChatRooms: chatRooms.isLoading,
    chatRooms: chatRooms.data,
    currentChannel,
    setCurrentChannel,
    currentChannelMessages: currentChannelMessages.data,
    fetchNextCurrentChannelMessages: currentChannelMessages.fetchNextPage,
    isInitialLoadingCurrentChannelMessages: currentChannelMessages.isInitialLoading,
    postNewMessage: postNewMessage.mutateAsync,
    isPostingNewMessage: postNewMessage.isLoading,
    removeMessageFromCurrentChannel: removeMessageFromCurrentChannel.mutateAsync,
    typingIndicatorForChannel,
    sendTypingSignal,
    addRequestFillChatInputListener,
    removeRequestFillChatInputListener,
    fillChatInput,
    ...unreadCounts,
    ...channelMetadata,
  };
};
