import styled from 'styled-components';
import { hexToRGB, WEBINAR_THEMES } from 'shared';
import { Text } from 'components';

export const StyledContainer = styled.div`
  height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr;
  background-color: ${hexToRGB(WEBINAR_THEMES.LIGHT_WHITE, 0.6)};
`;

export const StyledContent = styled.div`
  display: flex;
`;

export const StyledMainContent = styled.div`
  flex-grow: 1;
  height: 100%;
  overflow: auto;
`;

export const StyledEventEndsHeading = styled.p`
  color: ${WEBINAR_THEMES.DARK};
  font-size: 20px;
  margin-bottom: 30px;
`;

export const StyledEventEndsText = styled(Text)`
  font-size: 13px;
  text-align: center;

  a {
    color: ${WEBINAR_THEMES.RED};
  }
`;

export const StyledCloseCardButtonWrapper = styled.div`
  position: absolute;
  top: 12px;
  right: 10px;
`;
