import { PublisherProps, SubscriberProps, ViewParams } from "./StreamView";
import { StageLayout } from 'models/Stage';
export const getViewsToShow = (views, stage, screenSharingPublisher, OTPublisher, publisherSettings, layoutStreamsQueryInOrder, publisherIdsMap, oldViews = null) => {
    // ! YT TODO ENHANCE !!!
    if ((!stage)) { // || (!layoutStreamsQueryInOrder)
        // TODO Very strange workaround for brekingout events. If it isn't there the sort of views seems to become wrong
        // TODO and the views begin to shuffle a lot.
        return views.reverse()
    };

    let filtered_views = views
        .filter((view) => {
            const publisherParams = view as PublisherProps;

            let id = view.id;

            if (publisherParams.publisherProperties) {
                id = publisherIdsMap.current.get(id);
            }

            return stage.streams?.find((stream) => stream.id === id);
        })
        .map((view) => {
            const publisherParams = view as PublisherProps;
            if (!publisherParams.publisherProperties) {
                return view;
            }
            if (publisherParams.isScreenSharing && screenSharingPublisher?.stream) {
                return {
                    id: view.id,
                    isScreenSharing: true,
                    OTStream: screenSharingPublisher.stream,
                    silence: true,
                } as SubscriberProps;
            }
            if (OTPublisher?.stream) {
                const stream = OTPublisher.stream;
                stream.hasAudio = publisherSettings.publishAudio;
                return {
                    id: view.id,
                    OTStream: stream,
                    silence: true,
                } as SubscriberProps;
            }
        })
        .filter((view) => !!view);

    let final_filtered_views = []


    // ! YT WORKAROUND
    // "!stage" is added to handle breakout events
    if ((!stage) || (stage.layoutPreset?.type?.toLowerCase().includes(StageLayout.Dynamic))) {
        final_filtered_views = filtered_views
        // { id: 'empty-dummy', isScreenSharing: true }
    }
    else {
        if (!layoutStreamsQueryInOrder)
            return []
        const count = Math.max(layoutStreamsQueryInOrder.length, stage.layoutPreset?.sources?.length ?? 0)
        for (let i = 0; i < count; i++) {
            let noViewAdded = true
            const layoutElement = layoutStreamsQueryInOrder[i] ?? null;
            if (layoutElement) {
                const view = filtered_views.find((viewElement) => {
                    const user = JSON.parse((viewElement as any).OTStream.name)
                    return (user._id == layoutElement.userId) && ((viewElement as any).OTStream.videoType == layoutElement.videoType)
                })
                if (view) {
                    final_filtered_views.push(view)
                    noViewAdded = false
                }


            }
            if (noViewAdded) {
                final_filtered_views.push({ id: 'empty-dummy', isScreenSharing: true })

            }


        }
    }
    return final_filtered_views

}


export const getECView = (views, stage, screenSharingPublisher, OTPublisher, publisherSettings, layoutStreamsQueryInOrder, publisherIdsMap) => {
    // ! YT TODO ENHANCE !!!
    if ((!stage) || (!layoutStreamsQueryInOrder)) {
        return []
    };
    let filtered_views = views
        .filter((view) => {
            if (((view as any).OTStream) && (((view as any).OTStream.name) == 'Experience Composer Stream!')) {
                return true
            }

        })
        .map((view) => {
            const publisherParams = view as PublisherProps;
            if (!publisherParams.publisherProperties) {
                return view;
            }
            if (publisherParams.isScreenSharing && screenSharingPublisher?.stream) {
                return {
                    id: view.id,
                    isScreenSharing: true,
                    OTStream: screenSharingPublisher.stream,
                    silence: true,
                } as SubscriberProps;
            }
            if (OTPublisher?.stream) {
                const stream = OTPublisher.stream;
                stream.hasAudio = publisherSettings.publishAudio;
                return {
                    id: view.id,
                    OTStream: stream,
                    silence: true,
                } as SubscriberProps;
            }
        })
        .filter((view) => !!view);
    let final_filtered_views = []
    const view = filtered_views.find((viewElement) => {
        if (((viewElement as any).OTStream.name) == 'Experience Composer Stream!') {
            return true
        }

    })
    if (view) {
        final_filtered_views.push(view)
    }
    else {
        final_filtered_views.push({ id: 'empty-dummy', isScreenSharing: true })
    }
    final_filtered_views.push({ id: 'empty-dummy', isScreenSharing: true })


    return final_filtered_views

}
export const mapStreamsToViews = (streams, attendees, publisherSettings, screenSharingSettings, usePublisherAndScreenShareSettings = true) => {
    let views: ViewParams[] = streams.map((stream) => {

        // ! TODO YT: WORKAROUND.
        // ! ADDED to handle the stream produced by experience composer
        // ! Correct behaviour (experience composer stream shouldn't be read here)
        let userData: any
        if (typeof stream.name === 'string') {
            userData = stream.name;
        }
        else {
            userData = JSON.parse(stream.name);
        }

        return {
            id: stream.streamId,
            uuid: stream.streamId,
            isAttendee: !!attendees?.find((user) => user._id === userData._id),
            OTStream: stream,
            isScreenSharing: stream.videoType === 'custom',
        };
    });
    if (usePublisherAndScreenShareSettings) {
        if (publisherSettings) {
            views.push({
                id: 'publish',
                // uuid: stream.streamId,
                publisherProperties: publisherSettings,
            });
        }
        if (screenSharingSettings) {
            views.push({
                id: 'screen',
                isScreenSharing: true,
                publisherProperties: screenSharingSettings,
            });
        }

    }
    return views

}
