import { useGetEventWithAllPublicDetails } from 'lib/api/event';
import { StyledVideoContainer } from './PublicStage.styled'
import { useParams } from "react-router-dom";
import Presentation from 'Webinar/LiveStream/Presentation';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ViewParams } from 'Webinar/LiveStream/StreamView';
import {  useGetStageByUUID,  useSubscribeToStageLayoutChangesWithUUID, useSubscribeToStageStreamsChangesWithUUID } from 'lib/api/stage';
import { LayoutPresetSource } from 'models/LayoutPreset';
import { useOpentokSession } from 'Webinar/LiveStream/opentokSession';
import { mapStreamsToViews, getViewsToShow } from 'Webinar/LiveStream/LiveStreamUtils';
// import { useGetCurrentEventAttendees } from 'lib/api/attendees';
import { StageLayout } from 'models/Stage';
// import { useEventUrlKey } from 'shared';



const PublicStageComponent = () => {
  const { eventUUIDKey } = useParams();
  const { data: event } = useGetEventWithAllPublicDetails();
  const { data: stage } = useGetStageByUUID(eventUUIDKey, event?.mainStage?.urlKey);
  const [streamToken, setStreamToken] = useState<string>();

  const { OTSession, connectionError, isConnected, streams, kickOutStream } = useOpentokSession(
    stage?.backStageVideoRoom.providerId,
    event?.streamToken,
  );

  const [layoutStreamsQueryInOrder, setLayoutStreamsQueryInOrder] = useState<LayoutPresetSource[]>();


  useSubscribeToStageLayoutChangesWithUUID(eventUUIDKey, stage?.urlKey);
  useSubscribeToStageStreamsChangesWithUUID(eventUUIDKey, stage?.urlKey);
  const publisherIdsMap = useRef(new Map<string, string>());

  const views = useMemo<ViewParams[]>(() => {
    if (streams) {
      return mapStreamsToViews(streams, null, null, null)
    }
  }, [streams]);

  useEffect(() => {
    if (stage && stage.layoutPreset) {
      // ! TODO fix this workaround
      if (typeof stage.layoutPreset == 'string') {
        console.error("[PublicStage] stage.layoutPreset is STRING")
      }
      else {
        setLayoutStreamsQueryInOrder(stage.layoutPreset.sources)
      }
    }
  }, [stage]);
  const viewsToShow = useMemo<ViewParams[]>(
    () => {
      if (stage) {
        return getViewsToShow(views, stage, null, null, null, layoutStreamsQueryInOrder, publisherIdsMap)
      }
      else {
        return []
      }

    }, [views, OTSession, event,  layoutStreamsQueryInOrder, stage]);



  return (
    <StyledVideoContainer>

      <Presentation
        views={viewsToShow}
        OTSession={OTSession}
        layout={stage?.layoutPreset?.type ?? StageLayout.Contained}
        layoutAssets={stage?.layoutAssets}
        banner={null}
      // layoutStreamsQueryInOrder={layoutStreamsQueryInOrder}
      />

    </StyledVideoContainer>
  );
};



export default PublicStageComponent;
