import { FC, useEffect, useMemo, useRef, memo, useState } from 'react';
import styled from 'styled-components';
import {
  Stream as OTStream,
  PublisherProperties,
  Session,
  initPublisher,
  Publisher,
  SubscriberProperties,
} from '@opentok/client';
import { useGetCurrentUser } from 'lib/api/user';
import { getImageForDimensions, useEventUrlKey, useSessionUrlKey } from 'shared';
import { micMute } from 'assets/images/webinar/controls';
import OverlayText from './OverlayText';
import { usePublisherDict } from './PublishersDict';

const StreamVideoContainer = styled.div`
  height: 100%;
  border-radius: 8px;
  overflow: hidden;

  .OT_video-poster {
    background-position: center !important;
  }
`;

const StreamWithDetails = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
// height:100%; Added for sources in the tab.

const StreamDetails = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
  height: 38px;
  position: absolute;
  bottom: 0px;
  padding: 8px;
  width: 100%;
  z-index: 2;
  color: white;
`;

const StreamBottom: React.FC<{ name: string; isMute?: boolean }> = ({ name, isMute }) => {
  return (
    <StreamDetails>
      <span style={{ float: 'right' }}>{name}</span>
      {isMute ? micMute() : null}
    </StreamDetails>
  );
};

export interface SubscriberProps {
  id: string;
  uuid?: string;
  isAttendee?: boolean;
  OTStream: OTStream;
  isScreenSharing: boolean;
  silence?: boolean;
}

export interface UserData {
  _id: string;
  firstName: string;
  lastName: string;
  profileImageUrl: string;
  title?: string;
  position?: string;
}

export function getUserData(str: string): UserData | undefined {
  try {
    return JSON.parse(str) as UserData;
  } catch (e) {
    reportError(e);
    return undefined;
  }
}

const OTSubscriber: FC<{
  stream: OTStream;
  session: Session;
  isScreenSharing?: boolean;
  silence?: boolean;
  showOverlay?: boolean;
  animationDuration?: number;
  speakerDetails?: any;
  overlayRectangleColor?: any;
  streamWidth?: number;
  muted?: boolean;
  showMuteIcon?: boolean;
}> = ({
  stream,
  session,
  isScreenSharing,
  silence,
  showOverlay,
  animationDuration,
  speakerDetails,
  overlayRectangleColor = null,
  streamWidth = null,
  muted,
  showMuteIcon,
}) => {
  const ref = useRef<HTMLDivElement>();

  const userData = useMemo(() => {
    return getUserData(stream.name);
  }, [stream]);

  useEffect(() => {
    const userData = getUserData(stream.name);
    const params: SubscriberProperties = {
      width: '100%',
      height: '100%',
      fitMode: isScreenSharing ? 'contain' : 'cover',
      insertMode: 'append',
      showControls: false,
      style: {
        backgroundImageURI: getImageForDimensions(userData?.profileImageUrl, 320, 320),
      },
    };

    if (silence) {
      params.audioVolume = 0;
    }
    const sub = session.subscribe(stream, ref.current, params);
    return () => {
      session.unsubscribe(sub);
    };
  }, [session, stream, isScreenSharing, silence]);

  return (
    <StreamWithDetails>
      <StreamVideoContainer ref={ref} />
      {showOverlay &&
        speakerDetails &&
        (speakerDetails.firstName.trim().length > 0 ||
          speakerDetails.lastName.trim().length > 0) && (
          <OverlayText
            // name={userData.firstName + ' ' + userData.lastName}
            // position={userData.position}
            // name={speakerDetails? speakerDetails.firstName + ' ' + speakerDetails.lastName: userData? userData.firstName + ' ' + userData.lastName: ''}
            name={speakerDetails ? speakerDetails.firstName + ' ' + speakerDetails.lastName : ''}
            position={speakerDetails?.title}
            color="black"
            rectangleColor={overlayRectangleColor}
            yPosition={0.15}
            animation="slide-in"
            align="left" // Change to "left" or "right"
            animationDuration={animationDuration}
            streamWidth={streamWidth}
          />
        )}
      {showMuteIcon && <StreamBottom name="" isMute={muted && !isScreenSharing} />}
    </StreamWithDetails>
  );
};

export interface PublisherProps {
  id: string;
  isAttendee?: boolean;
  uuid?: string;
  isScreenSharing?: boolean;
  publisherProperties: PublisherProperties;
}

const OTPublisher: FC<{
  properties: PublisherProperties;
  session: Session;
  isScreenSharing?: boolean;
  setPublisher: (publisher: Publisher) => unknown;
  onPublisherStreamId?: (id: string) => unknown;
  showOverlay?: boolean;
  animationDuration?: number;
  speakerDetails?: any;
  viewId?: string;
  overlayRectangleColor?: any;
  streamWidth?: number;
}> = memo(
  ({
    properties,
    session,
    setPublisher,
    onPublisherStreamId,
    isScreenSharing,
    showOverlay,
    animationDuration,
    speakerDetails,
    viewId,
    overlayRectangleColor = null,
    streamWidth = null,
  }) => {
    const ref = useRef<HTMLDivElement>();
    const publisherRef = useRef<Publisher>();
    const { data: user } = useGetCurrentUser();

    const prevProperties = useRef<PublisherProperties>();
    const { addItem, checkExist, getItem } = usePublisherDict();
    const eventUrlKey = useEventUrlKey();
    const sessionUrlKey = useSessionUrlKey();

    /**
     *
     * @returns string that stand for event and session to be used to access the context of streams
     */
    const getEventSessionKey = () => {
      return `${eventUrlKey}-${sessionUrlKey}`;
    };

    useEffect(() => {
      if (!publisherRef.current && checkExist(getEventSessionKey(), viewId)) {
        publisherRef.current = getItem(getEventSessionKey(), viewId);
        ref.current.append(publisherRef.current.element);

        onPublisherStreamId?.(publisherRef.current.stream.streamId);
        setPublisher?.(publisherRef.current);
      }
      if (!publisherRef.current) {
        const userData: UserData = {
          _id: user._id,
          firstName: user.firstName,
          lastName: user.lastName,
          profileImageUrl: user.profileImageUrl,
          // position: "!! POSITION !!"
        };
        publisherRef.current = initPublisher(ref.current, {
          ...properties,
          width: '100%',
          height: '100%',
          showControls: false,
          fitMode: isScreenSharing ? 'contain' : 'cover',
          insertMode: 'append',
          name: JSON.stringify(userData),
          style: { backgroundImageURI: getImageForDimensions(user.profileImageUrl, 320, 320) },
        });
        session.publish(publisherRef.current, (error: Error) => {
          if (error) {
            console.error(`Error publishing: ${publisherRef.current.id}`);
            console.error(error);
            return;
          }
          onPublisherStreamId?.(publisherRef.current.stream.streamId);
          addItem(getEventSessionKey(), viewId, publisherRef.current);
        });
        setPublisher?.(publisherRef.current);
      } else {
        if (!prevProperties.current) {
          return;
        }
        if (properties.audioSource !== prevProperties.current.audioSource) {
          publisherRef.current.setAudioSource(properties.audioSource as string);
        }
        if (properties.publishAudio !== prevProperties.current.publishAudio) {
          publisherRef.current.publishAudio(properties.publishAudio);
        }
        if (properties.videoSource) {
          publisherRef.current.setVideoSource(properties.videoSource as string);
        }
        if (properties.publishVideo !== prevProperties.current.publishVideo) {
          publisherRef.current.publishVideo(properties.publishVideo);
        }
      }
      prevProperties.current = properties;
    }, [properties, session, setPublisher, isScreenSharing, user, onPublisherStreamId]);

    return (
      <StreamWithDetails>
        <StreamVideoContainer ref={ref} />
        {showOverlay &&
          speakerDetails &&
          (speakerDetails.firstName.trim().length > 0 ||
            speakerDetails.lastName.trim().length > 0) && (
            <OverlayText
              // name={user.firstName + ' ' + user.lastName}
              // position="Codenteam CEO"
              // name={speakerDetails? speakerDetails.firstName + ' ' + speakerDetails.lastName: user? user.firstName + ' ' + user.lastName: ''}
              name={speakerDetails ? speakerDetails.firstName + ' ' + speakerDetails.lastName : ''}
              position={speakerDetails?.title}
              yPosition={0.15}
              animation="slide-in"
              color="yellow"
              rectangleColor={overlayRectangleColor}
              align="right" // Change to "left" or "right"
              triggerAnimation={showOverlay}
              animationDuration={animationDuration}
              streamWidth={streamWidth}
            />
          )}

        <StreamBottom name="You" isMute={!properties.publishAudio && !isScreenSharing} />
      </StreamWithDetails>
    );
  },
);

export type ViewParams = SubscriberProps | PublisherProps;

const StreamView: FC<{
  params: ViewParams;
  session: Session;
  setPublisher?: (publisher: Publisher) => unknown;
  onPublisherStreamId?: (id: string) => unknown;
  showOverlay?: boolean;
  animationDuration?: number;
  speakerDetails?: any;
  overlayRectangleColor?: any;
  isModeration?: boolean;
  streamWidth?: number;
  showMuteIcon?: boolean;
  muted?:boolean;
}> = ({  
  params,
  session,
  setPublisher,
  onPublisherStreamId,
  showOverlay,
  animationDuration,
  speakerDetails,
  overlayRectangleColor = null,
  isModeration = false,
  streamWidth = null,
  showMuteIcon = false,
  muted
}) => {
  const publisherParams = params as PublisherProps;
  const subscriberParams = params as SubscriberProps;
  if (publisherParams.publisherProperties) {
    return (
      <OTPublisher
        viewId={params.id}
        setPublisher={setPublisher}
        showOverlay={showOverlay}
        overlayRectangleColor={overlayRectangleColor}
        animationDuration={animationDuration}
        session={session}
        isScreenSharing={publisherParams.isScreenSharing}
        speakerDetails={speakerDetails}
        properties={publisherParams.publisherProperties}
        onPublisherStreamId={onPublisherStreamId}
        streamWidth={streamWidth}
      />
    );
  }

  return (
    <OTSubscriber
      showMuteIcon={showMuteIcon}
      session={session}
      showOverlay={showOverlay}
      animationDuration={animationDuration}
      stream={subscriberParams.OTStream}
      muted={muted}
      isScreenSharing={params.isScreenSharing}
      silence={subscriberParams.silence}
      speakerDetails={speakerDetails}
      overlayRectangleColor={overlayRectangleColor}
      streamWidth={streamWidth}
    />
  );
};

export default StreamView;
