import { useEffect, useState, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { capitalizeFirstLetter } from 'shared';
import { useDebouncedCallback } from 'services';

/**
 * @description
 * @function useWindowSize
 *
 * This hook checks window size (width or height) and rerender app when it is necessary
 *
 * **/
const useWindowSize = (type = 'width') => {
  const [size, setSize] = useState(window[`inner${capitalizeFirstLetter(type)}`]);
  const resizeHandler = useDebouncedCallback(
    () => setSize(window[`inner${capitalizeFirstLetter(type)}`]),
    300,
  );

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  });

  return size;
};

export const useEventUrlKey = () => {
  const { webinarUrlKey } = useParams();
  return webinarUrlKey;
};

export const useEventUUIDKey = () => {
  const { eventUUIDKey } = useParams();
  return eventUUIDKey;
};

export const useSessionUrlKey = () => {
  const { sessionUrlKey } = useParams();
  return sessionUrlKey;
};

/**
 * @description
 * @function useScrollToView
 *
 * This hook is used to know, when header content is hidden
 *
 * **/

const isRefArray = (r) => 'length' in r;

const isTarget = (ref, event) => ref?.current?.contains(event.target);

const trueForAny = (array, condition) =>
  array.reduce((conditionAlreadyMet, value) => conditionAlreadyMet || condition(value), false);

/**
 * @description
 * @function useClickOutside
 *
 * This hook detects clicks outside element.
 *
 * **/
const useClickOutside = (ref, onclick) => {
  const handleClick = useCallback(
    (click) => {
      if (isRefArray(ref)) {
        if (trueForAny(ref, (ref) => isTarget(ref, click))) {
          return;
        }
      } else {
        if (isTarget(ref, click)) {
          return;
        }
      }
      onclick();
    },
    [onclick, ref],
  );

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [handleClick]);

  return ref;
};

/**
 * @description
 * @function useSyncWordsScripts
 *
 * This hook append SyncWords scripts to document body
 *
 * **/
const useSyncWordsScripts = (id) => {
  useEffect(() => {
    if (!id) {
      return;
    }

    window.recapdOptions = { isCaptionsOnlyLanguage: true, eventID: id, collapsed: true };
    const widget = document.createElement('script');
    widget.id = 'fw-js-jsonp';
    widget.src = 'https://recapd.com/js/v2/1601387927.recapd-widget.min.js';
    document.body.appendChild(widget);

    return () => {
      document.body.removeChild(widget);
      // eslint-disable-next-line no-unused-expressions
      document.querySelector('#fw-widgetWrapper')?.remove();
    };
  }, [id]);
};

/**
 * Returns isMuted participant status
 * @param {?Object} stream
 * @returns {boolean} isMuted status
 */
const useIsStreamMuted = (stream) => {
  const [hasAudio, setHasAudio] = useState(stream ? stream.hasAudio : true);

  useEffect(() => {
    if (!stream) return;

    stream.on('audioChanged', setHasAudio);
    return () => stream.off('audioChanged', setHasAudio);
  }, [stream]);

  return !hasAudio;
};

/**
 * This hook generates a unique string that persists across rerenders
 */
const useUniqString = () => useMemo(() => uuid().replace(/-/g, ''), []);

export { useWindowSize, useClickOutside, useSyncWordsScripts, useIsStreamMuted, useUniqString };
