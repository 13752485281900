import { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Session } from '@opentok/client';
import { LayoutAssets, StageLayout } from 'models/Stage';
import { useCustomLayout } from './CustomLayouts/layoutsCreators';
import Banner, { PresentationBanner } from './PresentationBanner';
import StreamView, { ViewParams } from './StreamView';

const Container = styled.div<{ backgroundColor?: string; backgroundImage?: string }>`
  display: grid;
  justify-content: center;
  background-color: ${({ backgroundColor }) => backgroundColor ?? 'black'};
  background-image: url('${({ backgroundImage }) => backgroundImage}');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  color: white;
  position: relative;
`;

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StreamContainer = styled.div`
  display: flex;
  justify-content: center;
  transition: all 0.2s;
`;

interface Props {
  views: ViewParams[];
  OTSession: Session;
  layout: StageLayout;
  layoutAssets?: LayoutAssets;
  banner?: PresentationBanner;
  speakersMap?: any;
}

const Presentation: FC<Props> = ({
  views,
  OTSession,
  layout,
  layoutAssets,
  banner,
  speakersMap,
}) => {
  const [containerWidth, setContainerWidth] = useState<number>();
  const [containerHeight, setContainerHeight] = useState<number>();
  const [startOverlayAnimation, setStartOverlayAnimation] = useState<boolean>(true);
  const animationDuration = 10;
  const resizeObserverRef = useRef<ResizeObserver>(
    new ResizeObserver((entries) => {
      const div = entries[0].contentRect;
      setContainerHeight(div.height);
      setContainerWidth(div.width);
    }),
  );

  function onContainerRender(div: HTMLDivElement) {
    if (div && resizeObserverRef.current) {
      resizeObserverRef.current.observe(div);
    }
  }

  function convertWidthPxtoNumber(widthPx: string) {
    if (!widthPx) {
      return null;
    }
    return +widthPx.split('px')[0];
  }

  useEffect(() => {
    const resizeObserver = resizeObserverRef.current;
    return () => {
      resizeObserver.disconnect();
    };
  }, []);
  const [overlayAnimationTimeout, setOverlayAnimationTimeout] = useState<any>(null);

  useEffect(() => {
    // let overlayAnimationTimeout;
    // // 1. Close preivous animation (if any)
    // setStartOverlayAnimation(false)
    // // 2. Start new animation(if we removed the timeout, the javascript optimizer will omit the first statement and animations will overlap)
    // setTimeout(
    //   function () {
    //     setStartOverlayAnimation(true)
    //   }, 100);
    if (overlayAnimationTimeout) {
      clearTimeout(overlayAnimationTimeout);
    }
    setStartOverlayAnimation(true);
    setOverlayAnimationTimeout(
      setTimeout(function () {
        setStartOverlayAnimation(false);
      }, animationDuration * 1000),
    );
  }, [layout, views]);

  const layouts = useCustomLayout(views, containerWidth, containerHeight, layout);
  if (views.length === 0) {
    return (
      <Container>
        {/* <Center>
          <h1>No one is streaming</h1>
        </Center> */}
      </Container>
    );
  }

  return (
    <Container
      backgroundColor={layoutAssets?.backgroundColor}
      backgroundImage={layoutAssets?.backgroundImage}
      ref={onContainerRender}
    >
      {views.map((params, index) => (
        <div key={`div-${params.id}-${params.id == 'empty-dummy' ? index : ''}`}>
          {params.id === 'empty-dummy' ? (
            <div
              style={{
                ...layouts?.[params.id],
                position: 'absolute',
              }}
              key={`dummy-container-${params.id}`}
            ></div>
          ) : (
            <StreamContainer
              style={{
                ...layouts?.[params.id],
                position: 'absolute',
              }}
              key={`StreamContainer-${params.id}`}
            >
              <StreamView
                isModeration={false}
                params={params}
                session={OTSession}
                showOverlay={startOverlayAnimation}
                overlayRectangleColor={layoutAssets?.backgroundColor}
                speakerDetails={speakersMap ? speakersMap[index] ?? null : null}
                animationDuration={animationDuration}
                key={`StreamView-${params.id}`}
                streamWidth={convertWidthPxtoNumber(layouts?.[params.id]?.width)}
              />
            </StreamContainer>
          )}
        </div>
      ))}
      {banner && <Banner banner={banner} />}
    </Container>
  );
};

export default Presentation;
