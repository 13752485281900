import { Dialog } from 'components';
import { ChangeEvent, useState } from 'react';
import { WEBINAR_THEMES, useEventUrlKey } from 'shared';
import styled from 'styled-components';
import { HexColorPicker } from 'react-colorful';
import { LayoutAssets } from 'models/Stage';
import {
  useGetCurrentEventStage,
  useUpdateStageLayoutAssets,
  useUpdateStageLayoutAssetsBgImage,
} from 'lib/api/stage';
import AddPhotoAlternate from '@material-ui/icons/AddPhotoAlternate';
import { Input } from 'components/ui/Field/components';
import { closeNotification } from 'assets';
import Button from 'components/ui/Button';

const BGButton = styled.button<{ color?: string; image?: string }>`
  width: 54px;
  height: 38px;
  border: 4px solid ${WEBINAR_THEMES.GREY};
  background-color: ${({ color }) => color ?? 'black'};
  border-radius: 8px;
  background-image: url('${({ image }) => image}');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`;

const BGDialog = styled(Dialog)``;

const StyledHeading = styled.h3`
  font-weight: 700;
  font-style: normal;
  color: ${WEBINAR_THEMES.DARK};
  font-size: 18px;
  letter-spacing: 0;
  line-height: 1.3;
  margin: 20px;
  text-align: center;
`;

const Container = styled.div`
  padding: 16px;
  display: grid;
  row-gap: 50px;
  justify-content: center;
`;

const ImageSelectContainer = styled.div`
  display: grid;
  justify-content: center;
`;

export const StyledBackgroundItem = styled.div<{ selected?: boolean }>`
  width: 200px;
  height: 200px;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  border: 2px solid ${WEBINAR_THEMES.GREY};

  &:hover {
    cursor: pointer;
    border: 2px solid ${({ theme }) => theme.itemsColor};
  }
`;

const StyledBackgroundItemStatic = styled(StyledBackgroundItem)<{ backgroundColor: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor};

  & .MuiSvgIcon-root {
    font-size: 25px;
    margin-top: 2px;
    color: ${WEBINAR_THEMES.GREY};
  }

  &:hover {
    cursor: ${({ selected }) => `${selected ? 'not-allowed' : 'pointer'}`};
    pointer-events: ${({ selected }) => `${selected ? 'none' : 'unset'}`};
    border-color: ${({ theme }) => theme.itemsColor};
  }
`;

const StyledInputFile = styled.input`
  position: absolute;
  height: 100%;
  opacity: 0;
  cursor: pointer;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const RestoreImageButton = styled.div`
  align-self: center;
`;

const InputsContainer = styled.div`
  display: flex;
  column-gap: 16px;
`;

const InputContainer = styled.div`
  display: grid;
  row-gap: 8px;
  justify-items: center;
`;

const SaveButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-block: 4px;
  column-gap: 16px;
`;

export const StyledCloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  margin: 0;
  padding: 0;
`;

const Background = () => {
  const { data: stage } = useGetCurrentEventStage();
  const eventUrlKey = useEventUrlKey();
  const [show, setShow] = useState(false);
  const [selectedColor, setSelectedColor] = useState(stage.layoutAssets?.backgroundColor);
  const [selectedImageFile, setSelectedImageFile] = useState<File>();
  const [tmpImageSrc, setTmpImageSrc] = useState<string>();
  const [shouldRemoveImage, setShouldRemoveImage] = useState<boolean>(false);

  const updateLayoutAssets = useUpdateStageLayoutAssets(eventUrlKey, stage.urlKey);
  const updateStageLayoutAssetsBgImage = useUpdateStageLayoutAssetsBgImage(
    eventUrlKey,
    stage.urlKey,
  );

  const onSave = async () => {
    if (selectedImageFile) {
      const formData = new FormData();
      formData.set('image', selectedImageFile);
      if (selectedColor) {
        formData.set('backgroundColor', selectedColor);
      }
      await updateStageLayoutAssetsBgImage.mutateAsync(formData);
    } else {
      const updateParams: Partial<LayoutAssets> = {};

      if (shouldRemoveImage) {
        updateParams.backgroundImage = '';
      }

      if (selectedColor) {
        updateParams.backgroundColor = selectedColor;
      }

      if (Object.keys(updateParams).length > 0) {
        await updateLayoutAssets.mutateAsync(updateParams);
      }
    }

    setSelectedColor(undefined);
    setSelectedImageFile(undefined);
    setShow(false);
  };

  const onFileSelect = (e: ChangeEvent) => {
    setShouldRemoveImage(false);
    const file = (e.target as HTMLInputElement).files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setTmpImageSrc(reader.result as string);
    };
    reader.readAsDataURL(file);
    setSelectedImageFile(file);
  };

  const imgSrc = tmpImageSrc || stage.layoutAssets?.backgroundImage;

  const clearImage = () => {
    setSelectedImageFile(undefined);
    setTmpImageSrc(undefined);
    setShouldRemoveImage(true);
  };

  const restoreDefault = () => {
    setSelectedColor('#000000');
    clearImage();
  };

  return (
    <>
      <p className="mb-4 mt-6">Background:</p>
      <BGButton
        disabled={updateLayoutAssets.isLoading}
        color={stage.layoutAssets?.backgroundColor ?? 'black'}
        image={stage.layoutAssets?.backgroundImage}
        onClick={() => setShow(true)}
      />
      <BGDialog
        open={show}
        onClose={() => {
          setShow(false);
        }}
      >
        <StyledCloseButton onClick={() => setShow(false)}>{closeNotification()}</StyledCloseButton>
        <StyledHeading>Background</StyledHeading>
        <Container>
          <InputsContainer>
            <InputContainer>
              <ImageSelectContainer>
                <StyledBackgroundItemStatic
                  backgroundColor={selectedColor || stage.layoutAssets?.backgroundColor}
                >
                  <StyledInputFile type="file" accept=".png,.jpeg,bmp" onChange={onFileSelect} />
                  {imgSrc && !shouldRemoveImage ? (
                    <StyledImage src={imgSrc} />
                  ) : (
                    <AddPhotoAlternate style={{ fontSize: 35, marginTop: 0 }} />
                  )}
                </StyledBackgroundItemStatic>
              </ImageSelectContainer>
              {imgSrc && (
                <RestoreImageButton>
                  <Button
                    variant="secondary"
                    onClick={clearImage}
                    disabled={
                      updateLayoutAssets.isLoading || updateStageLayoutAssetsBgImage.isLoading
                    }
                  >
                    Remove image
                  </Button>
                </RestoreImageButton>
              )}
            </InputContainer>
            <InputContainer>
              <HexColorPicker
                color={selectedColor ?? stage.layoutAssets?.backgroundColor}
                onChange={setSelectedColor}
              />
              <Input
                value={selectedColor ?? stage.layoutAssets?.backgroundColor}
                onChange={(e) => setSelectedColor(e.target.value)}
              />
            </InputContainer>
          </InputsContainer>
          <SaveButtonContainer>
            <Button
              variant="secondary"
              onClick={restoreDefault}
              disabled={updateLayoutAssets.isLoading || updateStageLayoutAssetsBgImage.isLoading}
            >
              Restore default
            </Button>
            <Button
              onClick={onSave}
              disabled={updateLayoutAssets.isLoading || updateStageLayoutAssetsBgImage.isLoading}
            >
              Save
            </Button>
          </SaveButtonContainer>
        </Container>
      </BGDialog>
    </>
  );
};
export default Background;
