import styled from 'styled-components';
import { Flex, hexToRGB, WEBINAR_THEMES } from 'shared';
import { Skeleton } from 'components/ui';

export const StyledContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr 80px;
`;

export const StyledList = styled.ul`
  padding-bottom: 20px;
`;

export const StyledLi = styled.li`
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${hexToRGB(WEBINAR_THEMES.DARK_GREY, 0.1)};

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }
`;

export const StyledSkeletons = styled.div`
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100px;
`;

export const StyledContent = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledSkeletonCircle = styled(Skeleton)`
  min-width: 30px;
  min-height: 30px;
  margin-right: 10px;
`;

export const StyledWrapper = styled.div`
  display: flex;
  align-items: center;

  & > .MuiSkeleton-root:first-child {
    margin-right: 10px;
  }
`;

export const StyledSkeletonWrapper = styled.div`
  position: absolute;
  width: calc(100% - 40px);
  overflow: hidden;
  margin-top: 30px;
`;

export const StyledCreateBlock = styled.div`
  border-top: 1px solid ${hexToRGB(WEBINAR_THEMES.DARK_GREY, 0.1)};
  display: flex;
  align-items: center;
  justify-content: stretch;
  max-height: 67px;
`;

export const StyledNoQuestionsWrapper = styled.div`
  margin-top: 100px;
  ${Flex.fullCenter};
  flex-direction: column;
`;

export const StyledNoQuestionsHeading = styled.h3`
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: ${WEBINAR_THEMES.DARK};
`;

export const StyledText = styled.p`
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  color: ${WEBINAR_THEMES.GREY};
`;



export const StyledCustomLayouts = styled.div`
  display: grid;
  grid-auto-flow: column;
  column-gap: 32px;
  justify-content: center;
`;

export const StyledCustomLayoutSection = styled.div`
  display: grid;
  row-gap: 4px;
`;

export const StyledCustomLayoutSectionLabel = styled.div`
  font-size: 10px;
  color: ${WEBINAR_THEMES.GREY};
  font-weight: normal;
`;


export const StyledNoLayoutsWrapper = styled.div`
  margin-top: 100px;
  ${Flex.fullCenter};
  flex-direction: column;
`;

export const StyledNoLayoutsHeading = styled.h3`
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: ${WEBINAR_THEMES.DARK};
`;

export const StyledCreateLayoutBlock = styled.div`
  padding-top: 15px;
  border-top: 1px solid ${hexToRGB(WEBINAR_THEMES.DARK_GREY, 0.1)};
`;
