import Pusher from 'pusher-js';
// TO debug pusher events to console
// Pusher.logToConsole = true;

Pusher.Runtime.createXHR = function () {
  const xhr = new XMLHttpRequest();
  xhr.withCredentials = true;
  return xhr;
};

export const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
  cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
  forceTls: process.env.REACT_APP_PUSHER_APP_CLUSTER,
  authEndpoint: process.env.REACT_APP_PUSHER_APP_AUTH_ENDPOINT,
  auth: {
    headers: {},
  },
});
