import { useRef, FC, RefObject } from 'react';
import { format } from 'date-fns';
import Linkify from 'react-linkify';
import DeleteIcon from '@material-ui/icons/Delete';
import { pinned as pinnedIcon } from 'assets';
import { WEBINAR_THEMES } from 'shared';
import {
  StyledMessageWrapper,
  StyledMessage,
  StyledButton,
  StyledText,
  StyledTime,
  StyledBlock,
  StyledName,
  StyledControls,
} from './message.styled';
import { useIsOrganizerOfCurrentWebinar, useWebinarTheme, WebinarTheme } from 'lib/webinar';
import { AppMessage } from 'lib/api/chat';
import { useGetCurrentUser } from 'lib/api/user';
import { Mention } from './Mention';
import reactStringReplace from 'react-string-replace';
import { isColorTooDark } from '../../../../../../lib/colors';
import { useGetCurrentEvent } from '../../../../../../lib/api/event';

const componentDecorator = (href: string, text: string, key: string) => (
  <a href={href} key={key} target="_blank" rel="noopener noreferrer">
    {text}
  </a>
);


interface Props {
  message: AppMessage;
  messageRef?: RefObject<HTMLDivElement>;
  theme: WebinarTheme;
  onRemoveMessage?: () => unknown;
  onPinMessage?: () => unknown;
}

export const Message: FC<Props> = ({
  message,
  messageRef,
  theme,
  onRemoveMessage,
  onPinMessage,
}) => {
  const { data: user } = useGetCurrentUser();
  const isOrganizer = useIsOrganizerOfCurrentWebinar();
  const { data: webinar } = useGetCurrentEvent();
  const { additionalColors } = useWebinarTheme(webinar);

  const messageEl = useRef(null);

  const me = message.meta.uuid === user._id;

  const name = me ? 'You' : `${message.meta.firstName} ${message.meta.lastName}`;

  return (
    <StyledMessageWrapper me={me}>
      <StyledBlock me={me}>
        <StyledName color={!me ? WEBINAR_THEMES.BLACK : undefined} title={name}>
          {name}
        </StyledName>
        <StyledTime>
          {format(Math.round(+message.timetoken / 10000), 'h:mm aaa').toUpperCase()}
        </StyledTime>
      </StyledBlock>
      <StyledMessage
        ref={messageRef}
        me={me}
        backgroundColor={me ? theme.additionalColors.primaryColor : WEBINAR_THEMES.WHITE}
      >
        {isOrganizer && (
          <StyledControls me={me}>
            {onRemoveMessage && (
              <StyledButton
                backgroundColor={theme.itemsColor}
                disableRipple
                onClick={onRemoveMessage}
              >
                <DeleteIcon fontSize="inherit" />
              </StyledButton>
            )}
            {onPinMessage && (
              <StyledButton backgroundColor={theme.itemsColor} disableRipple onClick={onPinMessage}>
                {pinnedIcon()}
              </StyledButton>
            )}
          </StyledControls>
        )}

        <StyledText ref={messageEl} color={me ? WEBINAR_THEMES.LIGHT_WHITE : WEBINAR_THEMES.DARK}>
          {/*fileTypeIcon[type] && <StyledFileIcon src={fileTypeIcon[type]} alt="file icon" />*/}
          <Linkify componentDecorator={componentDecorator}>
            {reactStringReplace(message.text, /(@\[.+?]\(\w+?\))/g, (match, i) => (
              <Mention
                key={i}
                value={match}
                theme={theme.additionalColors.secondaryColor}
                color={
                  WEBINAR_THEMES[
                    isColorTooDark(additionalColors.secondaryColor) ? 'WHITE' : 'BLACK'
                  ]
                }
              />
            ))}
          </Linkify>
        </StyledText>
      </StyledMessage>
    </StyledMessageWrapper>
  );
};
