import { useContext, useEffect, useState } from 'react';
import { useGetCurrentEvent } from 'lib/api/event';
import { useGetCurrentSession } from 'lib/api/session';
import { useIsOrganizerOfCurrentWebinar, useWebinarTheme } from 'lib/webinar';
import { Grow, Skeleton } from 'components';

import {
  StyledContainer,
  StyledList,
  StyledLi,
  StyledSkeletons,
  StyledContent,
  StyledSkeletonCircle,
  StyledSkeletonWrapper,
  StyledWrapper,
  StyledCreateBlock,
  StyledNoQuestionsWrapper,
  StyledNoQuestionsHeading,
  StyledText,
  StyledCustomLayouts,
  StyledCustomLayoutSection,
  StyledCustomLayoutSectionLabel,
} from './layouts.styled';
import Button from 'components/ui/Button';
import { useSubscribeToStageStreamsChanges } from 'lib/api/stage';
import { useEventUrlKey } from 'shared';
import { useGetCurrentEventStage } from 'lib/api/stage';
// ! TODO FIX THE FOLLOWING IMPORT to be in a unified place, or copy the required function here.
import { useMediaDevices } from 'Webinar/LiveStream/PublisherControls/HardwareSetupDialog/HardwareSetup';
import { LayoutPreset } from 'models/LayoutPreset';
import { LayoutPresetForm } from './LayoutForm';
import { useDeleteLayoutPreset, useGetLayoutPresets, useLayoutPresetParams } from 'lib/api/layoutPreset';
import { StageLayoutPresets } from 'Webinar/LiveStream/CustomLayouts/StageLayoutPresets';
import { OpenLayoutPresetTabContext } from 'contexts/openRightSidebarTab/openLayoutPresetTabContext';
import DeleteConfirmationDialog from '../../../../components/ui/DeleteDialog/DeleteLayoutPresetDialog';
// import { OpenRightSidebarTabContext } from 'contexts';

export const Layouts = () => {
  const [showLayoutPresetForm, setShowLayoutPresetForm] = useState(false);
  const [layoutPresetToEdit, setLayoutPresetToEdit] = useState<LayoutPreset>(null);

  const isOrganizer = useIsOrganizerOfCurrentWebinar();
  const { data: webinar } = useGetCurrentEvent();
  const theme = useWebinarTheme(webinar);

  const webinarId = webinar?._id;

  const eventUrlKey = useEventUrlKey();
  const { data: stage, _, refetch } = useGetCurrentEventStage();

  const layoutPresetsParams = useLayoutPresetParams();

  /**
   *
   * Delete Layout Preset Functions
   */
  const handleDeleteLayoutPresetButton = (l) => {
    openDeleteDialog(l)
  }

  const handleEditLayoutPreset = (l) => {
    setLayoutPresetToEdit(l)
  }
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<any | null>(null);
  const { mutate: deleteLayoutPreset } = useDeleteLayoutPreset(webinar._id, layoutPresetsParams)


  const openDeleteDialog = (item: any) => {
    setItemToDelete(item);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = (confirmed: boolean) => {
    setDeleteDialogOpen(false); // Close the dialog immediately on any user action
    if (confirmed && itemToDelete) {
      // Perform delete action here
      deleteLayoutPreset(itemToDelete._id)
      // If the deleted item is now open for editing, clear the editing form
      if (openedLayoutPresetTab && (openedLayoutPresetTab._id == itemToDelete._id)) {
        setLayoutPresetToEdit(null)
      }
    }
    // setItemToDelete(null);
  };


  const handleDuplicateLayoutPreset = (l) => {
    let { _id: _, ...clone } = l;
    setLayoutPresetToEdit(clone)
  }

  /******************************************************/

  useSubscribeToStageStreamsChanges(eventUrlKey, stage?.urlKey);

  const { data: mediaDevices, isLoading: isMediaDevicesLoading, error } = useMediaDevices();

  const { data: layoutPresets, isLoading } = useGetLayoutPresets(webinarId, layoutPresetsParams);

  const closeForm = () => {
    setShowLayoutPresetForm(false);
    setLayoutPresetToEdit(null);
  };
  const { openLayoutPresetTab, openedLayoutPresetTab } = useContext(
    OpenLayoutPresetTabContext,
  );

  useEffect(() => {
    if (openedLayoutPresetTab) {
      setLayoutPresetToEdit(openedLayoutPresetTab)

    }
  }, [openLayoutPresetTab, openedLayoutPresetTab]);

  const CreateLayoutPresetButton = (
    <StyledCreateBlock>
      <Button block onClick={() => setShowLayoutPresetForm(true)}>
        Create a Layout
      </Button>
    </StyledCreateBlock>
  );

  if (showLayoutPresetForm || layoutPresetToEdit) {
    return (
      <LayoutPresetForm
        layoutPreset={layoutPresetToEdit}
        webinarId={webinar._id}
        onRequestClose={closeForm}
        layoutPresetsParams={layoutPresetsParams}
      />
    );
  }

  if (isLoading) {
    <StyledContainer>
      return (
      <Grow in={true} timeout={300}>
        <StyledSkeletonWrapper>
          {Array(3)
            .fill(1)
            .map((_, ind) => (
              <StyledSkeletons key={ind}>
                <StyledContent>
                  <StyledSkeletonCircle variant="circle" />
                  <div style={{ width: '100%' }}>
                    <Skeleton
                      variant="rect"
                      height={10}
                      width="30%"
                      style={{ borderRadius: '10px', marginBottom: '5px' }}
                    />
                    <Skeleton
                      variant="rect"
                      height={10}
                      width="20%"
                      style={{ borderRadius: '10px' }}
                    />
                  </div>
                </StyledContent>
                <StyledWrapper>
                  <Skeleton
                    variant="rect"
                    height={15}
                    width="20%"
                    style={{ borderRadius: '10px' }}
                  />
                  <Skeleton
                    variant="rect"
                    height={15}
                    width="20%"
                    style={{ borderRadius: '10px' }}
                  />
                </StyledWrapper>
                <Skeleton variant="rect" height={25} width="100%" style={{ borderRadius: '4px' }} />
              </StyledSkeletons>
            ))}
        </StyledSkeletonWrapper>
      </Grow>
      );
    </StyledContainer>;
  }
  if (!layoutPresets || layoutPresets.length === 0) {
    return (
      <>
        <StyledContainer>
          <StyledNoQuestionsWrapper>
            <StyledNoQuestionsHeading>No layout presets yet</StyledNoQuestionsHeading>
            <StyledText>{isOrganizer ? 'No Preset Layout' : 'Add the first one'}</StyledText>
          </StyledNoQuestionsWrapper>
          {CreateLayoutPresetButton}
        </StyledContainer>
      </>
    );
  }
  return (
    <>

      <DeleteConfirmationDialog open={deleteDialogOpen} itemName={itemToDelete?.label} onClose={(e) => handleCloseDeleteDialog(e)}></DeleteConfirmationDialog>

      <StyledContainer>
        <StyledList>
          <StyledCustomLayouts>
            <StyledCustomLayoutSection>
              <StageLayoutPresets isTab={true} layoutPresets={layoutPresets} isLoading={isLoading} handleClickOnElement={handleEditLayoutPreset} handleDeleteLayoutPreset={handleDeleteLayoutPresetButton} handleEditLayoutPreset={handleEditLayoutPreset} handleDuplicateLayoutPreset={handleDuplicateLayoutPreset} />


            </StyledCustomLayoutSection>
          </StyledCustomLayouts>
        </StyledList>
        {CreateLayoutPresetButton}
      </StyledContainer>
    </>
  );
};
