import { useGetSessionPresence } from 'lib/api/presence';
import { useScheduleItemTime } from 'lib/scheduleItem';
import { Session } from 'models/Session';
import { getImageForDimensions, useEventUrlKey, WEBINAR_THEMES } from 'shared';
import emptySessionImage from 'assets/images/stage/empty-stage.png';

import { format } from 'date-fns';


import {Content,Image,ImageHolder,Item,List,Live,Over,ParticipantsPromptHolder,StartsIn,SubContent,Title, Duration} from './SessionsList.styles'
const SessionTimePrompt: React.FunctionComponent<{ session: Session }> = ({ session }) => {
  const eventUrlKey = useEventUrlKey();
  const { isOver, isUpcoming, timeToStart } = useScheduleItemTime(session.scheduleItem);
  const { data: presence } = useGetSessionPresence(eventUrlKey, session?.urlKey);

  if (isUpcoming) {
    return <StartsIn>Starts in {timeToStart}</StartsIn>;
  }

  if (isOver) {
    return <Over>Ended</Over>;
  }

  return (
    <Live>
      Live now | {presence} Participant{presence === 1 ? '' : 's'}
    </Live>
  );
};

const SessionItem: React.FunctionComponent<{ session: Session }> = ({ session }) => {
  const eventUrlKey = useEventUrlKey();
  const imageUrl = getImageForDimensions(session.imageUrl, 800, 440) || emptySessionImage;
  const { duration, isUpcoming, startTime } = useScheduleItemTime(session.scheduleItem);

  return (
    <Item key={session._id}>
      <ImageHolder>
        <Image src={imageUrl} />
      </ImageHolder>
      <Content>
        <Title to={`/webinars/${eventUrlKey}/sessions/${session.urlKey}`}>{session.title}</Title>
        <SubContent>
          <ParticipantsPromptHolder>
            <SessionTimePrompt session={session} />
          </ParticipantsPromptHolder>
          <Duration>
            Start{isUpcoming ? 's' : 'ed'} at {format(startTime, 'p')}
          </Duration>
          <Duration>Duration {duration}</Duration>
        </SubContent>
      </Content>
    </Item>
  );
};

interface Props {
  sessions: Session[];
}

const SessionsList: React.FunctionComponent<Props> = ({ sessions }) => {
  return (
    <List>
      {sessions
        .sort((a, b) => {
          return (
            new Date(a?.scheduleItem?.startsAtUtc ?? Date.now()).getTime() -
            new Date(b?.scheduleItem?.startsAtUtc ?? Date.now()).getTime()
          );
        })
        .map((session) => (
          <SessionItem session={session} key={session._id} />
        ))}
    </List>
  );
};

export default SessionsList;
