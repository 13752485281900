import styled from 'styled-components';
import { WEBINAR_THEMES } from 'shared';
import { Text } from '../Text';

export const StyledIcon = styled.p`
  & svg {
    width: 20px;
    height: 20px;
  }

  > .MuiBadge-root {
    vertical-align: top;
    min-height: 27px;
  }

  .Mui-selected & svg path {
    fill: ${({ itemsColor }) => itemsColor};
  }
`;

export const StyledTitle = styled(Text)`
  color: ${WEBINAR_THEMES.GREY};
  font-weight: 600;
  text-transform: capitalize;


  .Mui-selected & {
    color: ${({ itemsColor }) => itemsColor};
  }
`;
