import { OPEN_LAYOUT_PRESET_TAB } from './contextTypes';

const handlers = {
  [OPEN_LAYOUT_PRESET_TAB]: (state, { value }) => value,
  DEFAULT: (state) => state,
};

export const openLayoutPresetTabReducer = (state, action) => {
  const handler = handlers[action.type] || handlers.DEFAULT;
  return handler(state, action);
};
