export const VideoTypes =  [
    {
      value: 'camera',
      label: 'Camera',
    },
    {
      value: 'custom',
      label: 'Screen',
    },
];
export const videoTypesMap = {}
for (const videoType of VideoTypes) {
  videoTypesMap[videoType['value']] = videoType['label']
}
