import styled from 'styled-components';

export const StyledVideoContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: grid;
  row-gap: 1rem;
  grid-template-rows: 1fr auto auto;
  .OT_video-poster {
    background-size: 150px 150px !important;
  }
`;
