import { isColorTooDark } from 'lib/colors';
import styled, { keyframes } from 'styled-components';

const slideIn = keyframes`
  from {
    opacity: 0.6;
    transform: translateX(-10px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const Container = styled.div`
  position: absolute;
  bottom: 16px;
  left: 32px;
  right: 32px;
  z-index: 3;
  animation: ${slideIn} 0.2s linear;
`;

const Title = styled.div`
  padding: 16px;
  display: inline-block;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  background: ${({ theme }) => theme.additionalColors.secondaryColor};
  color: ${({ theme }) =>
    isColorTooDark(theme.additionalColors.secondaryColor) ? 'white' : 'black'};
`;

const Body = styled.div`
  padding: 16px;
  background: ${({ theme }) => theme.itemsColor};
  color: ${({ theme }) => (isColorTooDark(theme.itemsColor) ? 'white' : 'black')};
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
`;

export interface PresentationBanner {
  title?: string;
  body: string;
}

const Banner: React.FC<{ banner: PresentationBanner }> = ({ banner }) => {
  return (
    <Container>
      {banner.title && <Title>{banner.title}</Title>}
      <Body>{banner.body}</Body>
    </Container>
  );
};

export default Banner;
