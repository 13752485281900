import { useMutation, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { useApiClient } from '.';
import { LayoutAssets, Stage, StageLayout, StageStream } from 'models/Stage';

import { LayoutPreset } from 'models/LayoutPreset'
import { MIXPANEL_EVENTS, useEventUrlKey } from 'shared';
import { useEffect } from 'react';
import { Mixpanel, pusher } from 'services';
import { QueryParams } from './client';
import { Event } from '../../models/Event';

const getKey = (eventUrlKey: string, stageUrlKey?: string) => [
  'event',
  eventUrlKey,
  'stage',
  stageUrlKey,
];
const getKeyUUID = (eventUUIDKey: string, stageUrlKey?: string) => [
  'eventUUID',
  eventUUIDKey,
  'stage',
  stageUrlKey,
];

type SelectStagesFunc = (stages: Stage[]) => unknown;

export const useGetStages = (eventUrlKey: string, select?: SelectStagesFunc) => {
  const apiClient = useApiClient();
  return useQuery(
    ['event', eventUrlKey, 'stages'],
    async () => {
      const { data } = await apiClient.get<{ data: { stages: Stage[] } }>(
        `/events/${eventUrlKey}/stages`,
        { include: 'layoutPreset' },
      );
      return data.stages;
    },
    {
      select,
    },
  );
};

export const useCreateStreamToken = (eventUrlKey: string, stageUrlKey: string) => {
  const apiClient = useApiClient();
  return useMutation(async () => {
    const { data } = await apiClient.get<{ data: { token: string } }>(
      `/events/${eventUrlKey}/stages/${stageUrlKey}/video-token`,
    );
    return data.token;
  });
};


export const useStartExperienceComposer = (stage: any, sessionId: string, urlKey: string) => {
  /**
   * urlKey is irrelevant from the public url that should be used for experience composer. urlKey should be used only to retrive the correct event.
   */
  const apiClient = useApiClient();
  return useMutation(async () => {
    const { data } = await apiClient.post<any>(
      `/ec`,
      {
        stage: stage,
        sessionId: sessionId,
        urlKey: urlKey,

      }
    );
    return data.token;
  });
};


export const useStopExperienceComposer = (experienceComposerId: string) => {
  /**
   * urlKey is irrelevant from the public url that should be used for experience composer. urlKey should be used only to retrive the correct event.
   */
  const apiClient = useApiClient();
  return useMutation(async () => {
    const { data } = await apiClient.delete<any>(
      `/ec/${experienceComposerId}`,
    );
    return data.token;
  });
};





export const useSubscribeToStageStreamsChangesWithUUID = (eventUUIDKey: string, stageUrlKey: string) => {
  const queryClient = useQueryClient();
  const { data: stage } = useGetStageByUUID(eventUUIDKey, stageUrlKey);
  useEffect(() => {
    if (!stage) return;
    const streamsChannel = pusher.subscribe(
      `private-event-${stage.event}-stages-${stage._id}-streams`,
    );
    streamsChannel.bind('streams-changed', ({ streams }) => {
      queryClient.setQueryData<Stage>(getKeyUUID(eventUUIDKey, stageUrlKey), (stage) => ({
        ...stage,
        streams,
      }));
    });
    return () => {
      streamsChannel.unsubscribe();
    };
  }, [stage, eventUUIDKey, queryClient, stageUrlKey]);
};

export const useSubscribeToStageLayoutChangesWithUUID = (eventUUIDKey: string, stageUrlKey: string) => {
  const queryClient = useQueryClient();
  const { data: stage } = useGetStageByUUID(eventUUIDKey, stageUrlKey);
  useEffect(() => {
    if (!stage) return;
    const layoutChannel = pusher.subscribe(
      `private-event-${stage.event}-stages-${stage._id}-layout`,
    );
    layoutChannel.bind('layout-changed', ({ layout }) => {
      queryClient.setQueryData<Stage>(getKeyUUID(eventUUIDKey, stageUrlKey), (stage) => ({
        ...stage,
        layout,
      }));
    });
    const layoutAssetsChannel = pusher.subscribe(
      `private-event-${stage.event}-stages-${stage._id}-layout-assets`,
    );
    layoutAssetsChannel.bind('layout-changed', ({ layoutAssets }) => {
      queryClient.setQueryData<Stage>(getKeyUUID(eventUUIDKey, stageUrlKey), (stage) => ({
        ...stage,
        layoutAssets,
      }));
    });
    return () => {
      layoutChannel.unsubscribe();
      layoutAssetsChannel.unsubscribe();
    };
  }, [stage, eventUUIDKey, queryClient, stageUrlKey]);
};
















export const useGetCurrentEventStages = (select?: SelectStagesFunc) => {
  const eventUrlKey = useEventUrlKey();
  return useGetStages(eventUrlKey, select) as UseQueryResult<Stage[]>;
};

export const useGetCurrentEventStage = () => {
  const eventUrlKey = useEventUrlKey();
  const select: SelectStagesFunc = (stages) => stages[0];
  const { data: stage } = useGetCurrentEventStages(select) as UseQueryResult<Stage>;
  return useGetStage(eventUrlKey, stage?.urlKey);
};

export const useGetStageByUUID = (eventUUIDKey: string, stageUrlKey: string)=> {
  const apiClient = useApiClient();
  return useQuery(
    getKeyUUID(eventUUIDKey, stageUrlKey),

    async () => {
      const queryParams: QueryParams = {
        projections: ['backStageVideoRoom', 'layoutPreset', 'experienceComposer'],
      };
      const { data } = await apiClient.get<{ data: { stage: Stage } }>(
        `/events/by-uuid/${eventUUIDKey}/stages/${stageUrlKey}`,
        queryParams,
      );
      return data.stage;

    },
    { enabled: !!stageUrlKey },
  );
};



export const useGetStage = (eventUrlKey: string, stageUrlKey: string) => {
  const apiClient = useApiClient();
  return useQuery(
    getKey(eventUrlKey, stageUrlKey),
    async () => {
      const queryParams: QueryParams = {
        projections: ['backStageVideoRoom', 'layoutPreset', 'experienceComposer'],
      };
      const { data } = await apiClient.get<{ data: { stage: Stage } }>(
        `/events/${eventUrlKey}/stages/${stageUrlKey}`,
        queryParams,
      );
      return data.stage;

    },
    { enabled: !!stageUrlKey },
  );
};

export const useUpdateStageLayoutPreset = (eventUrlKey: string, stageUrlKey: string) => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  return useMutation({

    async mutationFn(layoutPreset: LayoutPreset) {
      let x = await apiClient.put(`/events/${eventUrlKey}/stages/${stageUrlKey}/layout-preset`, {
        layoutPreset: layoutPreset._id,
        // layoutPreset
      });
      return x;
    },
    onMutate(layoutPreset: LayoutPreset | string) {
      const key = getKey(eventUrlKey, stageUrlKey);
      const previousStage = queryClient.getQueryData<Stage>(key);
      queryClient.setQueryData<Stage>(key, (stage) => ({ ...stage, layoutPreset }));

      return { previousStage };
    },
    onError(err, layout, context) {
      queryClient.setQueryData<Stage>(getKey(eventUrlKey, stageUrlKey), context.previousStage);
    },
    // ! TODO Ysf Workaround. For solving the problem of retrieving layoutPreset as string. I re-retrive it. ((Probably causing an error in selection of the layout preset in the GUI. ))
    // onSettled: () => {
    //   queryClient.invalidateQueries(getKey(eventUrlKey, stageUrlKey))
    // },

  });
};

export const useUpdateStageLayout = (eventUrlKey: string, stageUrlKey: string) => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  return useMutation({
    async mutationFn(layout: StageLayout) {
      await apiClient.put(`/events/${eventUrlKey}/stages/${stageUrlKey}/layout`, {
        layout,
      });
      return layout;
    },
    onMutate(layout: StageLayout) {
      const key = getKey(eventUrlKey, stageUrlKey);
      const previousStage = queryClient.getQueryData<Stage>(key);
      queryClient.setQueryData<Stage>(key, (stage) => ({ ...stage, layout }));
      return { previousStage };
    },
    onError(err, layout, context) {
      queryClient.setQueryData<Stage>(getKey(eventUrlKey, stageUrlKey), context.previousStage);
    },
  });
};

export const useUpdateStageLayoutAssets = (eventUrlKey: string, stageUrlKey: string) => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  return useMutation({
    async mutationFn(layoutAssets: LayoutAssets) {
      const { data } = await apiClient.put<{ data: { layoutAssets: LayoutAssets } }>(
        `/events/${eventUrlKey}/stages/${stageUrlKey}/layout-assets`,
        layoutAssets,
      );
      return data.layoutAssets;
    },
    onSuccess(layoutAssets: LayoutAssets) {
      const key = getKey(eventUrlKey, stageUrlKey);
      const previousStage = queryClient.getQueryData<Stage>(key);
      queryClient.setQueryData<Stage>(key, (stage) => ({ ...stage, layoutAssets }));
      return { previousStage };
    },
  });
};

export const useUpdateStageLayoutAssetsBgImage = (eventUrlKey: string, stageUrlKey: string) => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  return useMutation({
    async mutationFn(formData: FormData) {
      const { data } = await apiClient.put<{ data: { layoutAssets: LayoutAssets } }>(
        `/events/${eventUrlKey}/stages/${stageUrlKey}/layout-assets/bg-image`,
        formData,
      );
      return data.layoutAssets;
    },
    onSuccess(layoutAssets: LayoutAssets) {
      const key = getKey(eventUrlKey, stageUrlKey);
      const previousStage = queryClient.getQueryData<Stage>(key);
      queryClient.setQueryData<Stage>(key, (stage) => ({ ...stage, layoutAssets }));
      return { previousStage };
    },
  });
};

export const useAddStreamToStage = (eventUrlKey: string, stageUrlKey: string) => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  return useMutation({
    async mutationFn(streamId: string) {
      const { data } = await apiClient.post<{ data: { streams: StageStream[] } }>(
        `/events/${eventUrlKey}/stages/${stageUrlKey}/streams`,
        { id: streamId },
      );
      return data.streams;
    },
    onSuccess(streams: StageStream[]) {
      const key = getKey(eventUrlKey, stageUrlKey);
      const previousStage = queryClient.getQueryData<Stage>(key);
      queryClient.setQueryData<Stage>(key, (stage) => ({ ...stage, streams }));
      return { previousStage };
    },
  });
};

export const useRemoveStreamFromStage = (eventUrlKey: string, stageUrlKey: string) => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  return useMutation({
    async mutationFn(streamId: string) {
      const { data } = await apiClient.delete<{ data: { streams: StageStream[] } }>(
        `/events/${eventUrlKey}/stages/${stageUrlKey}/streams/${streamId}`,
      );
      return data.streams;
    },
    onSuccess(streams: StageStream[]) {
      const key = getKey(eventUrlKey, stageUrlKey);
      const previousStage = queryClient.getQueryData<Stage>(key);
      queryClient.setQueryData<Stage>(key, (stage) => ({ ...stage, streams }));
      return { previousStage };
    },
  });
};

export const useSubscribeToStageStreamsChanges = (eventUrlKey: string, stageUrlKey: string) => {
  const queryClient = useQueryClient();
  const { data: stage } = useGetStage(eventUrlKey, stageUrlKey);
  useEffect(() => {
    if (!stage) return;
    const streamsChannel = pusher.subscribe(
      `private-event-${stage.event}-stages-${stage._id}-streams`,
    );
    streamsChannel.bind('streams-changed', ({ streams }) => {
      queryClient.setQueryData<Stage>(getKey(eventUrlKey, stageUrlKey), (stage) => ({
        ...stage,
        streams,
      }));
    });
    return () => {
      streamsChannel.unsubscribe();
    };
  }, [stage, eventUrlKey, queryClient, stageUrlKey]);
};

export const useSubscribeToStageLayoutChanges = (eventUrlKey: string, stageUrlKey: string) => {
  const queryClient = useQueryClient();
  const { data: stage } = useGetStage(eventUrlKey, stageUrlKey);
  useEffect(() => {
    if (!stage) return;
    const layoutChannel = pusher.subscribe(
      `private-event-${stage.event}-stages-${stage._id}-layout`,
    );
    layoutChannel.bind('layout-changed', ({ layout }) => {
      queryClient.setQueryData<Stage>(getKey(eventUrlKey, stageUrlKey), (stage) => ({
        ...stage,
        layout,
      }));
    });
    const layoutAssetsChannel = pusher.subscribe(
      `private-event-${stage.event}-stages-${stage._id}-layout-assets`,
    );
    layoutAssetsChannel.bind('layout-changed', ({ layoutAssets }) => {
      queryClient.setQueryData<Stage>(getKey(eventUrlKey, stageUrlKey), (stage) => ({
        ...stage,
        layoutAssets,
      }));
    });
    return () => {
      layoutChannel.unsubscribe();
      layoutAssetsChannel.unsubscribe();
    };
  }, [stage, eventUrlKey, queryClient, stageUrlKey]);
};

export const useStageLayout = (eventUrlKey: string, stageUrlKey: string) => {

  const { mutateAsync, isLoading } = useUpdateStageLayoutPreset(eventUrlKey, stageUrlKey);
  const { data: stage } = useGetStage(eventUrlKey, stageUrlKey);
  return {
    layoutPreset: stage?.layoutPreset,
    isLoading,
    updateStageLayoutPreset: mutateAsync,
  };
};

export const useAddStageReaction = (event: Event, stage: Stage) => {
  const apiClient = useApiClient();
  return useMutation(
    async (emoji: { id: string; name: string }) => {
      await apiClient.post(`/events/${event.urlKey}/stages/${stage.urlKey}/reactions`, {
        emoji,
        socketId: pusher.connection.socket_id,
      });
      return emoji;
    },
    {
      onSuccess: (emoji) => {
        Mixpanel.track(MIXPANEL_EVENTS.stageReactionCreated.success, {
          emojiName: emoji.id,
          eventId: event._id,
          stageId: stage._id,
          stageTitle: stage.title,
        });
      },
    },
  );
};

export const useSubscribeToStageReactions = (
  eventId: string,
  stageId: string,
  onReaction: (data: { emojiName: string }) => unknown,
) => {
  useEffect(() => {
    const channel = pusher.subscribe(`private-event-${eventId}-stages-${stageId}-reactions`);
    channel.bind('new-stage-reaction', (data: { emojiName: string }) => {
      onReaction(data);
    });
    return () => {
      channel.unsubscribe();
    };
  }, [eventId, stageId, onReaction]);
};
