
import styled from 'styled-components';
import { WEBINAR_THEMES } from 'shared';
import { Link } from 'react-router-dom';

export const List = styled.div`
  padding: 16px 4px;
  display: grid;
  row-gap: 16px;
`;

export const Item = styled.div`
  display: flex;
  padding: 16px 2px;
  column-gap: 16px;
  border: 1px solid ${WEBINAR_THEMES.GREY};
  border-radius: 8px;
  position: relative;
  background-color: white;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

export const ImageHolder = styled.div`
  flex-basis: 100px;
  flex-grow: 0;
  display: flex;
  align-items: center;
`;

export const Image = styled.img`
  width: 100%;
  object-fit: contain;
  border-radius: 8px;
`;

export const Content = styled.div`
  display: grid;
  row-gap: 8px;
`;

export const Title = styled(Link)`
  font-size: 16px;
  font-weight: 600;
  color: ${WEBINAR_THEMES.BLACK};

  &::after {
    content: ' ';
    position: absolute;
    inset: 0;
  }
  &:visited {
    color: ${WEBINAR_THEMES.BLACK};
  }
`;

export const SubContent = styled.div`
  display: grid;
  row-gap: 2px;
  font-size: 12px;
  font-weight: 400;
`;

export const ParticipantsPromptHolder = styled.div`
  display: flex;
  column-gap: 4px;
`;

export const StartsIn = styled.div`
  color: ${WEBINAR_THEMES.CORAL};
`;

export const Over = styled.div`
  color: ${WEBINAR_THEMES.RED};
`;

export  const Live = styled.div`
  color: ${WEBINAR_THEMES.GREEN};
`;

export  const Duration = styled.div``;
