export const CroppedLayout = () => (
  <svg width="100%" height="100%" viewBox="0 0 54 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="54" height="38" rx="8" fill="currentColor" />
    <rect x="1.99951" y="2" width="24" height="34" rx="2" fill="#D9D9D9" />
    <g clipPath="url(#clip0_13_3)">
      <path
        d="M14 19C15.8416 19 17.3333 17.5083 17.3333 15.6667C17.3333 13.825 15.8416 12.3333 14 12.3333C12.1583 12.3333 10.6666 13.825 10.6666 15.6667C10.6666 17.5083 12.1583 19 14 19ZM14 20.6667C11.775 20.6667 7.33331 21.7833 7.33331 24V25.6667H20.6666V24C20.6666 21.7833 16.225 20.6667 14 20.6667Z"
        fill="#464648"
      />
    </g>
    <rect x="27.9995" y="2" width="24" height="34" rx="2" fill="#D9D9D9" />
    <g clipPath="url(#clip1_13_3)">
      <path
        d="M40 19C41.8417 19 43.3333 17.5083 43.3333 15.6667C43.3333 13.825 41.8417 12.3333 40 12.3333C38.1583 12.3333 36.6667 13.825 36.6667 15.6667C36.6667 17.5083 38.1583 19 40 19ZM40 20.6667C37.775 20.6667 33.3333 21.7833 33.3333 24V25.6667H46.6667V24C46.6667 21.7833 42.225 20.6667 40 20.6667Z"
        fill="#464648"
      />
    </g>
    <defs>
      <clipPath id="clip0_13_3">
        <rect x="4" y="9" width="20" height="20" rx="4" fill="white" />
      </clipPath>
      <clipPath id="clip1_13_3">
        <rect x="30" y="9" width="20" height="20" rx="4" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
