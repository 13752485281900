import { Publisher } from '@opentok/client';
import React, { createContext, useContext, useState } from 'react';

// Create the context with default values
const PublisherDictContext = createContext();

// Provider component
export const PublisherDictProvider = ({ children }) => {
  const [dict, setDict] = useState({});

  const getItem = (eventSessionKey, viewId) : Publisher =>{
    return dict[eventSessionKey][viewId]
  }
  const checkExist = (eventSessionKey, viewId) =>{
    if (! (eventSessionKey in dict) ) return false
    return (viewId in dict[eventSessionKey])
  }
  const addItem = (eventSessionKey, viewId, item) => {
    let d = dict
    if (! (eventSessionKey in dict) ) {
      d[eventSessionKey] = {}
    }
    d[eventSessionKey][viewId] = item
    setDict(d)
  };

  const removeItem = (key) => {
    let d = dict
    delete d[key]
    setDict(d)
  };

  const unsubscribeAll = (eventSessionKey, session)=>{
    if (! (eventSessionKey in dict))
      return;
    for (const key in dict[eventSessionKey]) {
      if (Object.prototype.hasOwnProperty.call(dict[eventSessionKey], key)) {
        session.unpublish(dict[eventSessionKey][key])
      }
    }
  }

  return (
    <PublisherDictContext.Provider value={{ array: dict, addItem, removeItem, checkExist, unsubscribeAll, getItem }}>
      {children}
    </PublisherDictContext.Provider>
  );
};

// Custom hook to use the context
export const usePublisherDict = () => useContext(PublisherDictContext);
