export const StaticTwoAndOneSpotPresentationLayout = () => (
  <svg width="100%" height="100%" viewBox="0 0 54 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="54" height="38" rx="8" fill="currentColor" />
    <rect x="1" y="8.875" width="12" height="9" rx="1" fill="#D9D9D9" />
    <rect x="1" y="18.875" width="12" height="9" rx="1" fill="#D9D9D9" />
    <text x="3.5" y="27" fill="#464648" fontSize="10px">2</text>
    <text x="4.5" y="17" fill="#464648" fontSize="10px">1</text>

    <rect x="14" y="4.125" width="38" height="28.5" rx="1" fill="#575757" />
    <text x="28" y="25" fill="#D9D9D9" fontSize="16px">3</text>

  </svg>
);
