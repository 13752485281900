export const StaticOneAndOneSpotNewsLayout = () => (
  <svg width="100%" height="100%" viewBox="0 0 54 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="54" height="38" rx="8" fill="currentColor" />
    <rect x="18" y="2" width="33" height="34" rx="1" fill="#575757" />
    <rect x="3" y="2" width="15" height="34" rx="2" fill="#D9D9D9" />

    <text x="30" y="25" fill="#D9D9D9" fontSize="16px">2</text>
    <text x="6.5" y="25" fill="#575757" fontSize="16px">1</text>

    <defs>
      <clipPath id="clip0_94_58">
        <rect x="4" y="12" width="13" height="13" rx="4" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
