import { Source } from 'models/Source';
import { useApiClient } from '.';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Speaker } from 'models/Speaker';

const webinarSpeakersKey = (webinarId) => ["webinar-speakers", webinarId];

export const useGetWebinarSpeakers = (webinarId) => {
	const apiClient = useApiClient();
	return useQuery(
		webinarSpeakersKey(webinarId),
		async () => {
			const { data } = await apiClient.get<{ data: { speakers: Speaker[] } }>(
				`/manager/speakers?eventId=${webinarId}&projections=user`
			);
			return data.speakers;
		},
		{ enabled: !!webinarId }
	);
};
