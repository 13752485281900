import { useContext, useEffect } from 'react';
import { close, open } from 'assets/images/webinar';
import { CollapseRightSidebarContext } from 'contexts';
import { SidebarContent } from './SidebarContent';
import styled from 'styled-components';
import { Breakpoint, WEBINAR_THEMES } from 'shared';

const StyledSection = styled.div<{ collapsed?: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: ${({ collapsed }) => `${collapsed ? 'auto' : 'visible'}`};
  min-width: ${({ collapsed }) => `${collapsed ? '0' : '35%'}`};
  width: ${({ collapsed }) => `${collapsed ? '0' : '35%'}`};
  padding-inline: ${({ collapsed }) => `${collapsed ? '0' : '16px'}`};
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
  transition: 0.5s;
  background-color: ${WEBINAR_THEMES.WHITE};
  z-index: 1;
  // padding-bottom: 60px;
  height: 100%;
  overflow: hidden;

  @media screen and (max-width: ${Breakpoint.tablet}px) {
    min-width: ${({ collapsed }) => `${collapsed ? '0' : '100%'}`};
    width: ${({ collapsed }) => `${collapsed ? '0' : '100%'}`};
  }

  @media screen and (max-width: ${Breakpoint.laptopL}px) and (min-width: ${Breakpoint.tablet}px) {
    min-width: ${({ collapsed }) => `${collapsed ? '0' : '50%'}`};
    width: ${({ collapsed }) => `${collapsed ? '0' : '50%'}`};
  }
`;

const StyledButtonCollapse = styled.button<{ collapsed?: boolean }>`
  position: fixed;
  transition: 0.2s;
  z-index: 11;
  -webkit-transform: translateZ(0);
  margin-top: ${({ collapsed }) => `${collapsed ? '20px' : '8px'}`};
  margin-left: ${({ collapsed }) => `${collapsed ? '-35px' : '-40px'}`};
`;

export const RightSidebar = ({ itemsColor }) => {
  const { collapsedRightSidebar, setCollapseRightSidebar } = useContext(
    CollapseRightSidebarContext,
  );

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setCollapseRightSidebar(true);
      } else {
        setCollapseRightSidebar(false);
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <StyledSection collapsed={collapsedRightSidebar}>
      <StyledButtonCollapse
        collapsed={collapsedRightSidebar}
        onClick={() => setCollapseRightSidebar(!collapsedRightSidebar)}
      >
        <span>{collapsedRightSidebar ? open(itemsColor) : close(itemsColor)}</span>
      </StyledButtonCollapse>
      <SidebarContent />
    </StyledSection>
  );
};
