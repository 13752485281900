import styled from 'styled-components';
import { WEBINAR_THEMES } from 'shared';
import { Text } from 'components';

export const StyledSection = styled.section`
  padding: 16px;
  width: 420px;
`;

// eslint-disable-next-line no-unused-vars
export const StyledTitle = styled(({ color, ...props }) => <Text {...props} />)`
  text-align: center;
  margin-bottom: 20px;
  color: ${({ color }) => (color ? color : WEBINAR_THEMES.RED)};
`;

export const StyledContainer = styled.div`
  text-align: center;
`;

export const StyledSelectionContainer = styled.div`
  display: grid;
  row-gap: 1rem;
`;

export const StyledPreviewContainer = styled.div`
  display: grid;
  row-gap: 1rem;
  text-align: left;
  width: 389px;
`;

export const StyledCameraMicDiv = styled.div`
  width:389px;
`;

export const StyledDiv = styled.div`
  background-color: ${WEBINAR_THEMES.BLACK};
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const StyledVideoPreview = styled.video`
  width: 70%;
  object-fit: contain;
  transform: rotateY(180deg);
`;

export const StyledAudioPreviewContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 1rem;
  align-items: center;
`;

export const StyledAudioPreview = styled.div`
  width: 100%;
  height: 22px;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
`;

export const StyledAudioPreviewBar = styled.div.attrs<{ size?: number }>(({ size = 0 }) => ({
  style: {
    width: `${size}%`,
  },
}))<{ size?: number }>`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  transition: width 0.1s;
  background-color: ${WEBINAR_THEMES.GREEN};
`;

export const StyledBtnWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

export const StyledCloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  margin: 0;
  padding: 0;
`;
