import { useQuery } from '@tanstack/react-query';
import { useApiClient } from '.';
import { Event } from '../../models/Event';
import { useEventUUIDKey, useEventUrlKey } from '../../shared';

const getKeyForURLKey = (urlKey: string) => ['event', urlKey];
const getKeyForUUIDKey = (uuidKey: string) => ['eventUUID', uuidKey];




export const useGetEventForURLKey = (urlKey: string) => {
  const apiClient = useApiClient();
  return useQuery(
    getKeyForURLKey(urlKey),
    async () => {
      const { data } = await apiClient.get<{ data: { event: Event } }>(`/events/${urlKey}`);
      return data.event;
    },
    { enabled: !!urlKey },
  );
};

export const useGetCurrentEvent = () => {
  const eventUrlKey = useEventUrlKey();
  return useGetEventForURLKey(eventUrlKey);
};
export const useGetEventWithAllPublicDetails = () => {
  const eventUUIDKey = useEventUUIDKey();
  return useGetEventForUUIDKey(eventUUIDKey);
};

export const useGetEventForUUIDKey = (uuidKey: string) => {
  const apiClient = useApiClient();
  return useQuery(
    getKeyForUUIDKey(uuidKey),
    async () => {
      const { data } = await apiClient.get<{data: { event: Event }}>(`/events/by-uuid/${uuidKey}`);
      return data.event;
    },
    { enabled: !!uuidKey },
  );
};
