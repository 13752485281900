import { useState, FC, useRef, useEffect, useMemo } from 'react';
import { Skeleton, Select } from 'components';
import {
  StyledBtnWrapper,
  StyledCameraMicDiv,
  StyledDiv,
  StyledPreviewContainer,
  StyledSelectionContainer,
  StyledVideoPreview,
} from './hardwareSetupDialog.styled';
import { useGetCurrentEvent } from 'lib/api/event';
import { useWebinarTheme } from 'lib/webinar';
import { useQuery } from '@tanstack/react-query';
import Button from 'components/ui/Button';

export const useMediaDevices = () =>
  useQuery({
    queryKey: ['media-devices'],
    queryFn: async () => {
      if (!navigator.mediaDevices?.enumerateDevices) {
        throw new Error('Can not list stream devices');
      }
      await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      const devices = await navigator.mediaDevices.enumerateDevices();
      const cams = devices.filter((device) => device.kind === 'videoinput');
      const mics = devices.filter((device) => device.kind === 'audioinput');
      return { cams, mics };
    },
  });

interface Props {
  currentAudioStreamId?: string;
  currentVideoStreamId?: string;
  onApply: (sources: { audioSource?: string; videoSource?: string }) => unknown;
  onClose: () => unknown;
}

export const HardwareSetup: FC<Props> = ({
  onApply,
  onClose,
  currentAudioStreamId,
  currentVideoStreamId,
}) => {
  const { data: mediaDevices, isLoading, error } = useMediaDevices();

  const [selectedVideoSourceId, setSelectedVideoSourceId] = useState<string>(currentVideoStreamId);
  const [selectedAudioSourceId, setSelectedAudioSourceId] = useState<string>(currentAudioStreamId);

  useEffect(() => {
    if (currentAudioStreamId) {
      setSelectedAudioSourceId(currentAudioStreamId);
    }
  }, [currentAudioStreamId]);

  useEffect(() => {
    if (currentVideoStreamId) {
      setSelectedVideoSourceId(currentVideoStreamId);
    }
  }, [currentVideoStreamId]);

  const [audioStream, setAudioStream] = useState<MediaStream>();

  const { data: webinar } = useGetCurrentEvent();
  const theme = useWebinarTheme(webinar);

  const videoRef = useRef<HTMLVideoElement>();

  const camOptions = useMemo(() => {
    if (!mediaDevices?.cams) return [];
    return mediaDevices.cams.map((cam) => ({ label: cam.label, value: cam.deviceId }));
  }, [mediaDevices?.cams]);

  const micOptions = useMemo(() => {
    if (!mediaDevices?.mics) return [];
    return mediaDevices.mics.map((cam) => ({ label: cam.label, value: cam.deviceId }));
  }, [mediaDevices?.mics]);

  const camVal = useMemo(() => {
    if (!selectedVideoSourceId || !mediaDevices?.cams) return null;
    const camSource = mediaDevices.cams.find((cam) => cam.deviceId === selectedVideoSourceId);
    return { label: camSource.label, value: camSource.deviceId };
  }, [selectedVideoSourceId, mediaDevices?.cams]);

  const micVal = useMemo(() => {
    if (!selectedAudioSourceId || !mediaDevices?.mics) return null;
    const micSource = mediaDevices.mics.find((mic) => mic.deviceId === selectedAudioSourceId);
    return { label: micSource.label, value: micSource.deviceId };
  }, [selectedAudioSourceId, mediaDevices?.mics]);

  useEffect(() => {
    if (!mediaDevices) return;

    setSelectedAudioSourceId((id) => {
      if (id) return id;
      return mediaDevices.mics[0]?.deviceId;
    });

    setSelectedVideoSourceId((id) => {
      if (id) return id;
      return mediaDevices.cams[0]?.deviceId;
    });
  }, [mediaDevices]);

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    if (selectedVideoSourceId) {
      navigator.mediaDevices
        .getUserMedia({
          video: {
            deviceId: selectedVideoSourceId,
          },
        })
        .then((stream) => {
          if (videoRef.current) {
            videoRef.current.srcObject = stream;
            videoRef.current.play();
          }
        });
    } else {
      videoRef.current.pause();
      videoRef.current.srcObject = null;
    }
  }, [selectedVideoSourceId]);

  useEffect(() => {
    let mediaStream: MediaStream;
    if (selectedAudioSourceId) {
      navigator.mediaDevices
        .getUserMedia({
          audio: {
            deviceId: selectedAudioSourceId,
          },
        })
        .then((stream) => {
          setAudioStream(stream);
        });
    } else {
      setAudioStream(null);
    }
    return () => {
      mediaStream?.getTracks().forEach((t) => t.stop());
    };
  }, [selectedAudioSourceId]);

  const handleApply = () => {
    const publisherOptions = {
      audioSource: selectedAudioSourceId,
      videoSource: selectedVideoSourceId,
    };
    onApply(publisherOptions);
  };

  if (isLoading) {
    return (
      <div>
        <Skeleton variant="rect" width={'100%'} height={203} />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <h1>Error setting camera or microphone</h1>
        <Button block onClick={onClose}>
          <span>Close</span>
        </Button>
      </div>
    );
  }

  return (
    <StyledSelectionContainer>
      <StyledPreviewContainer>
        <StyledDiv>
          <StyledVideoPreview ref={videoRef} />
        </StyledDiv>
      </StyledPreviewContainer>
      <StyledPreviewContainer>
        <StyledCameraMicDiv>
          <span>Camera:</span>
          <Select
            theme={theme}
            options={camOptions}
            value={camVal}
            onChange={(opt: { label: string; value: string }) =>
              setSelectedVideoSourceId(opt.value)
            }
          />
        </StyledCameraMicDiv>
        <StyledCameraMicDiv>
          <span>Mic:</span>
          <Select
            theme={theme}
            options={micOptions}
            value={micVal}
            onChange={(opt: { label: string; value: string }) =>
              setSelectedAudioSourceId(opt.value)
            }
          />
        </StyledCameraMicDiv>
      </StyledPreviewContainer>
      <StyledBtnWrapper>
        <Button block onClick={handleApply}>
          <span>Apply</span>
        </Button>
      </StyledBtnWrapper>
    </StyledSelectionContainer>
  );
};
