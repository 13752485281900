export const StaticTwoByTwoCroppedStaticLayout = () => (
<svg width="100%" height="100%" viewBox="0 0 54 38" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="54" height="38" rx="8" fill="currentColor" />
  <rect x="1.99951" y="2" width="24" height="34" rx="2" fill="#D9D9D9" />
  <rect x="27.9995" y="2" width="24" height="34" rx="2" fill="#D9D9D9" />
  <text x="35" y="25" fill="#464648" fontSize="16px">2</text>
  <text x="10" y="25" fill="#464648" fontSize="16px">1</text>

  <defs>
    <clipPath id="clip0_13_3">
      <rect x="4" y="9" width="20" height="20" rx="4" fill="white" />
    </clipPath>
    <clipPath id="clip1_13_3">
      <rect x="30" y="9" width="20" height="20" rx="4" fill="white" />
    </clipPath>
  </defs>
</svg>
);
