import { useCallback, useEffect, useState } from 'react';
import { initSession, OTError, Session, Stream } from '@opentok/client';

const ApiKey = process.env.REACT_APP_OPEN_TOK_API_KEY;

const ForceUnpublishSignalType = 'forceUnpublish';

export const useOpentokSession = (
  sessionId: string,
  token: string,
  onStreamDestroyed?: (streamId: string) => unknown,
) => {
  const [connectionError, setConnectionError] = useState<OTError>();
  const [isConnected, setIsConnected] = useState(false);
  const [streams, setStreams] = useState<Stream[]>([]);

  const [OTSession, setOTSession] = useState<Session>();

  const kickOutStream = useCallback(
    (streamId: string) => {
      const stream = streams.find((s) => s.streamId === streamId);
      OTSession.forceUnpublish(stream, () => {
        setStreams((streams) => {
          const currentStreamIndex = streams.findIndex((s) => s.streamId === stream.streamId);
          if (currentStreamIndex === -1) {
            return streams;
          }
          streams.splice(currentStreamIndex, 1);
          return streams.slice();
        });
      });
      OTSession.signal({ type: ForceUnpublishSignalType, data: streamId }, (err) => {
        console.error(err);
      });
    },
    [OTSession, streams],
  );

  useEffect(() => {
    setStreams([]);
    const OTSession = initSession(ApiKey, sessionId);
    setOTSession(OTSession);

    return () => {
      OTSession.disconnect();
    };
  }, [sessionId]);

  useEffect(() => {
    if (!OTSession) return;

    function removeStreamById(streamId: string) {
      setStreams((streams) => {
        const currentStreamIndex = streams.findIndex((s) => s.streamId === streamId);
        if (currentStreamIndex === -1) {
          return streams;
        }
        streams.splice(currentStreamIndex, 1);
        return streams.slice();
      });
    }

    OTSession.on('streamPropertyChanged', (event) => {
      setStreams((streams) => {
        const currentIndex = streams.findIndex((s) => s.streamId === event.stream.streamId);
        if (currentIndex === -1) {
          return streams;
        }
        return [
          ...streams.slice(0, currentIndex),
          event.stream,
          ...streams.slice(currentIndex + 1),
        ];
      });
    });

    OTSession.on('streamCreated', (event) => {
      setStreams((streams) => {
        return [...streams, event.stream];
      });
    });

    OTSession.on('streamDestroyed', (event) => {
      removeStreamById(event.stream.streamId);
      onStreamDestroyed?.(event.stream.streamId);
    });

    OTSession.on('signal:' + ForceUnpublishSignalType, (event) => {
      //@ts-expect-error
      const streamId = event.data as string;
      removeStreamById(streamId);
      onStreamDestroyed?.(streamId);
    });

    return () => {
      OTSession.off('streamPropertyChanged');
      OTSession.off('streamCreated');
      OTSession.off('streamDestroyed');
      OTSession.off('signal:' + ForceUnpublishSignalType);
    };
  }, [OTSession, onStreamDestroyed]);

  useEffect(() => {
    if (!OTSession) {
      return;
    }
  }, [OTSession]);

  useEffect(() => {
    if (!OTSession || !token || isConnected) {
      return;
    }

    OTSession.connect(token, (err) => {
      if (err) {
        setConnectionError(err);
      } else {
        setIsConnected(true);
      }
    });
  }, [OTSession, token, isConnected]);

  return {
    OTSession,
    connectionError,
    isConnected,
    streams,
    kickOutStream,
  };
};
