import { StageLayout } from 'models/Stage';
import { useMemo } from 'react';
import { SubscriberProps, ViewParams } from '../StreamView';

interface Layout {
  top?: number;
  left?: number;
  bottom?: number;
  right?: number;
  zIndex?: number;
  width: string;
  height: string;
  padding?: string;
}

export const useCustomLayout = (
  views: ViewParams[],
  containerWidth: number,
  containerHeight: number,
  layout: StageLayout,
  dynamic: boolean = false // Added to allow old way of handling screenshare with wider view (if true, having wider screen share has higher priority than the chosen layout)
) =>
  useMemo<Record<string, Layout> | undefined>(() => {

    // ! TODO HERE add more layout types and separate static and dynamic choices when appropriate.
    if (!containerHeight || !containerWidth) return {};

    const hasScreenSharing = views.some((view) => view.isScreenSharing);


    if (dynamic && hasScreenSharing && (layout !== StageLayout.PictureInPicture) && (layout !== StageLayout.News)) {
      return getScreenSharingLayout(views, containerHeight, containerWidth);
    }
    if (layout === StageLayout.StaticPresentation) {
      console.log("getStaticPresentation")
      return getScreenSharingLayout(views, containerHeight, containerWidth, true);

    }

    if (layout === StageLayout.StaticPictureInPicture) {
      return getStaticPIPLayout(views, containerHeight, containerWidth);

    }
    if ((layout === StageLayout.PictureInPicture)) {
      return getPIPLayout(views, containerHeight, containerWidth);
    }
    if (layout === StageLayout.StaticNews) {
      return getNewsLayout(views, containerHeight, containerWidth, true);
    }
    if (layout === StageLayout.News) {
      return getNewsLayout(views, containerHeight, containerWidth);
    }
    if (layout === StageLayout.StaticCropped) {
      return getCroppedLayout(views, containerHeight, containerWidth);
    }

    if (layout === StageLayout.Cropped) {
      return getCroppedLayout(views, containerHeight, containerWidth);
    }
    return getDefaultLayout(views, containerHeight, containerWidth);
  }, [views, containerWidth, containerHeight, layout]);

const camViewWidth = 200;
const camViewHeight = (camViewWidth * 2) / 3;

export function getScreenSharingLayout(
  views: ViewParams[],
  containerHeight: number,
  containerWidth: number,
  static_mode: boolean = false
) {
  const results: Record<string, Layout> = {};
  let screenView: ViewParams;
  const nonScreenViews: ViewParams[] = [];
  if (static_mode) {
    /**To make it static */
    nonScreenViews.push(views[0])
    nonScreenViews.push(views[1])
    screenView = views[2]
    /*********************/

  }
  else {
    for (const view of views) {
      if (view.isScreenSharing) {
        screenView = view;
      } else {
        nonScreenViews.push(view);
      }
    }
  }


  const rows = Math.floor(containerHeight / camViewHeight);
  const columns = Math.ceil(nonScreenViews.length / rows);
  const camColumnsViewWidth = columns * camViewWidth;

  const screenViewWidth = containerWidth - camColumnsViewWidth;

  results[screenView.id] = {
    left: columns * camViewWidth,
    top: 0,
    height: containerHeight + 'px',
    width: screenViewWidth + 'px',
    padding: '22px',
  };

  const totalHeights = rows * camViewHeight;
  let globalTopOffset = 0;
  if (totalHeights < containerHeight) {
    globalTopOffset = (containerHeight - totalHeights) / 2;
  }

  for (let i = 0; i < nonScreenViews.length; i++) {
    const column = Math.floor(i / rows);
    const row = i % rows;
    const left = column * camViewWidth;
    const viewsInColumn = nonScreenViews.length - column * rows;
    const columnTotalHeights = viewsInColumn * camViewHeight;

    let columnTopOffset = 0;
    if (columnTotalHeights < totalHeights) {
      columnTopOffset = (totalHeights - columnTotalHeights) / 2;
    }

    const top = row * camViewHeight + globalTopOffset + columnTopOffset;
    results[nonScreenViews[i].id] = {
      top,
      left,
      height: camViewHeight + 'px',
      width: camViewWidth + 'px',
      padding: '8px',
    };
  }
  return results;
}

export function getNewsLayout(
  views: ViewParams[],
  containerHeight: number,
  containerWidth: number,
  static_mode: boolean = false
) {
  const results: Record<string, Layout> = {};
  let screenView: ViewParams;
  const nonScreenViews: ViewParams[] = [];

  if (static_mode) {
    screenView = views[0]
    nonScreenViews.push(views[1])

  }
  else {
    for (const view of views) {
      if (view.isScreenSharing) {
        screenView = view;
      } else {
        nonScreenViews.push(view);
      }
    }

  }


  const isScreenPublisher = 'publisherProperties' in screenView;

  const thirdContainerWidth = containerWidth / 3;

  results[screenView.id] = {
    left: thirdContainerWidth,
    top: 0,
    height: containerHeight + 'px',
    width: thirdContainerWidth * 2 + 'px',
    padding: '0',
  };

  for (const view of nonScreenViews) {
    const isSpeaker =
      (isScreenPublisher && 'publisherProperties' in view) ||
      (!isScreenPublisher &&
        (screenView as SubscriberProps).OTStream &&
        (view as SubscriberProps).OTStream &&
        (screenView as SubscriberProps).OTStream.name ===
        (view as SubscriberProps).OTStream.name) ||
      nonScreenViews.length === 1;
    if (isSpeaker) {
      results[view.id] = {
        left: 0,
        bottom: 0,
        padding: '0',
        height: containerHeight + 'px',
        width: thirdContainerWidth + 'px',
      };
    } else {
      results[view.id] = {
        height: '0',
        width: '0',
      };
    }
  }

  return results;
}
export function getStaticPIPLayout(views: ViewParams[], containerHeight: number, containerWidth: number) {
  const results: Record<string, Layout> = {};
  results[views[0].id] = {
    left: 0,
    top: 0,
    height: containerHeight + 'px',
    width: containerWidth + 'px',
    padding: '22px',
  };
  results[views[1].id] = {
    left: containerWidth - 8 - camViewWidth,
    bottom: 12,
    padding: '22px',
    zIndex: 3,
    height: camViewHeight + 'px',
    width: camViewWidth + 'px',
  };
  return results;

}

export function getPIPLayout(views: ViewParams[], containerHeight: number, containerWidth: number) {
  const results: Record<string, Layout> = {};
  let screenView: ViewParams;
  const nonScreenViews: ViewParams[] = [];

  for (const view of views) {
    if (view.isScreenSharing) {
      screenView = view;
    } else {
      nonScreenViews.push(view);
    }
  }

  const isScreenPublisher = 'publisherProperties' in screenView;

  results[screenView.id] = {
    left: 0,
    top: 0,
    height: containerHeight + 'px',
    width: containerWidth + 'px',
    padding: '22px',
  };

  for (const view of nonScreenViews) {
    const isSpeaker =
      (isScreenPublisher && 'publisherProperties' in view) ||
      (!isScreenPublisher &&
        (screenView as SubscriberProps).OTStream &&
        (view as SubscriberProps).OTStream &&
        (screenView as SubscriberProps).OTStream.name ===
        (view as SubscriberProps).OTStream.name) ||
      nonScreenViews.length === 1;
    if (isSpeaker) {
      results[view.id] = {
        left: 8,
        bottom: 12,
        padding: '22px',
        zIndex: 3,
        height: camViewHeight + 'px',
        width: camViewWidth + 'px',
      };
    } else {
      results[view.id] = {
        height: '0',
        width: '0',
      };
    }
  }

  return results;
}

export function getNumberOfColumns(numberOfViews: number) {
  if (numberOfViews === 1) {
    return 1;
  } else if (numberOfViews < 5) {
    return 2;
  } else if (numberOfViews < 7) {
    return 3;
  }
  return 4;
}

export function getCroppedLayout(
  views: ViewParams[],
  containerHeight: number,
  containerWidth: number,
) {
  const results: Record<string, Layout> = {};

  const columns = getNumberOfColumns(views.length);
  const width = containerWidth / columns;
  const rows = Math.ceil(views.length / columns);
  const height = containerHeight / rows;

  for (let i = 0; i < views.length; i++) {
    const row = Math.floor(i / columns);
    const column = i % columns;
    let leftOffset = 0;
    const viewsInRow = views.length - row * columns;
    if (viewsInRow < columns) {
      const totalViewsWidth = viewsInRow * width;
      leftOffset = (containerWidth - totalViewsWidth) / 2;
    }
    const top = row * height;
    const left = column * width + leftOffset;
    results[views[i].id] = {
      top,
      left,
      height: height + 'px',
      width: width + 'px',
      padding: '8px',
    };
  }

  return results;
}

export function getDefaultLayout(
  views: ViewParams[],
  containerHeight: number,
  containerWidth: number,
) {
  const results: Record<string, Layout> = {};

  const columns = getNumberOfColumns(views.length);
  const widthForColumn = containerWidth / columns;
  const heightForRow = (widthForColumn * 2) / 3;
  const rowsForColumns = Math.ceil(views.length / columns);
  let totalHeights = heightForRow * rowsForColumns;

  let width = widthForColumn;
  let height = heightForRow;
  if (totalHeights > containerHeight) {
    height = Math.floor(containerHeight / rowsForColumns);
    width = (height * 4) / 3;
    totalHeights = height * rowsForColumns;
  }

  const heightOffset = (containerHeight - totalHeights) / 2;
  const generalLeftOffset = (containerWidth - columns * width) / 2;
  const rowMaxWidth = columns * width;

  for (let i = 0; i < views.length; i++) {
    const row = Math.floor(i / columns);
    let leftOffset = 0;
    const viewsInRow = views.length - row * columns;
    if (viewsInRow < columns) {
      const totalViewsWidth = viewsInRow * width;
      leftOffset = (rowMaxWidth - totalViewsWidth) / 2;
    }
    const top = row * height + heightOffset;
    const column = i % columns;
    const left = column * width + leftOffset + generalLeftOffset;
    results[views[i].id] = {
      top,
      left,
      height: height + 'px',
      width: width + 'px',
      padding: '8px',
    };
  }
  return results;
}
