import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { Dialog } from 'components';
import React from 'react';

interface DeleteConfirmationDialogProps {
    open: boolean;
    onClose: (confirmed: boolean) => void;
    itemName?: string; // Optional prop to display the name of the item to be deleted
  }

  const DeleteConfirmationDialog: React.FC<DeleteConfirmationDialogProps> = ({ open, onClose, itemName }) => {

    const handleCancel = () => {
      onClose(false);
    };

    const handleConfirm = () => {
      onClose(true);
    };

    return (
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Are you sure you want to delete ${itemName ? `"${itemName}"` : 'this item'}? This action cannot be undone.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  export default DeleteConfirmationDialog;
