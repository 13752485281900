export const StaticTwoPIPLayout = () => (
  <svg width="100%" height="100%" viewBox="0 0 54 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="54" height="38" rx="8" fill="currentColor" />
    <rect x="3" y="2" width="48" height="34" rx="1" fill="#575757" />
    <rect x="38" y="26" width="12" height="9" rx="1" fill="#D9D9D9" />

    <text x="22" y="25" fill="#D9D9D9" fontSize="16px">1</text>
    <text x="41.2" y="33.5" fill="#575757" fontSize="8px">2</text>

  </svg>

);
