export const StaticTwoByTwoContainedStaticLayout = () => (
  <svg width="100%" height="100%" viewBox="0 0 54 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="54" height="38" rx="8" fill="currentColor" />
    <rect x="2" y="10" width="24" height="18" rx="1" fill="#D9D9D9" />
    <rect x="28" y="10" width="24" height="18" rx="1" fill="#D9D9D9" />
  <text x="35" y="25" fill="#464648" fontSize="16px">2</text>
  <text x="10" y="25" fill="#464648" fontSize="16px">1</text>

    <defs>
      <clipPath id="clip0_14_19">
        <rect x="8" y="13" width="11.52" height="11.88" rx="4" fill="white" />
      </clipPath>
      <clipPath id="clip1_14_19">
        <rect x="34" y="13" width="12" height="12" rx="4" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
