import * as yup from 'yup';

export const layoutPresetValidationSchema = yup.object({
  label: yup.string().required('Required'),
  type: yup.string().required(),
  event: yup.string(),
  session: yup.string(),
  sources: yup
    .array(
      yup.object({
        userId: yup.string().required('Required'),
        speakerId: yup.string().required('Required'),
        videoType: yup.string().required('Required'),
      }),
    )
    .min(1),
  isPublished: yup.boolean(),
});
