import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useApiClient } from '.';
import { LayoutPreset } from 'models/LayoutPreset';
import { useEffect, useMemo } from 'react';
import { pusher } from '../../services';
import { WEBINAR_PUSHER_EVENTS } from '../../shared';
import { useIsOrganizerOfCurrentWebinar } from 'lib/webinar';
import { useGetCurrentSession } from './session';
import { useParams } from 'react-router-dom';

export type LayoutPresetParams = { session?: string; };

const getKey = (webinarId: string, params?: LayoutPresetParams) => ['webinar', webinarId, 'layoutPreset', params];

const getSingleLayoutPresetKey = (webinarId: string, layoutPresetId: string) => [
  'webinar',
  webinarId,
  'layoutPreset',
  layoutPresetId,
];

const useAddLayoutPresetToQuery = (webinarId: string, params?: LayoutPresetParams) => {
  const queryClient = useQueryClient();
  return (layoutPreset: LayoutPreset) => {

    // ! commented the following (TODO CHECK )
    // if (
    //   (layoutPreset.session && !params.session) ||
    //   (!layoutPreset.session && params.session) ||
    //   layoutPreset.session !== params.session
    // ) {
    //   return;
    // }
    queryClient.setQueryData<LayoutPreset[]>(getKey(webinarId, params), (LayoutPresets = []) => {
      LayoutPresets = LayoutPresets.slice();
      const LayoutPresetIndex = LayoutPresets.findIndex((p) => p._id === layoutPreset._id);
      if (LayoutPresetIndex === -1) {
        LayoutPresets.push(layoutPreset);
      }
      return LayoutPresets;
    });
  };
};


const useRemoveLayoutPresetFromQuery = (webinarId: string, params?: LayoutPresetParams) => {
  const queryClient = useQueryClient();
  return (LayoutPresetId: string) => {
    queryClient.setQueryData<LayoutPreset[]>(getKey(webinarId, params), (LayoutPresets = []) => {
      LayoutPresets = LayoutPresets.slice();
      return LayoutPresets.filter((p) => p._id !== LayoutPresetId);
    });
  };
};

const useUpdateLayoutPresetInQuery = (webinarId: string, params?: LayoutPresetParams) => {
  const queryClient = useQueryClient();
  return (LayoutPresetId: string, updateParams: Partial<LayoutPreset>) => {
    queryClient.setQueryData<LayoutPreset[]>(getKey(webinarId, params), (LayoutPresets = []) => {
      LayoutPresets = LayoutPresets.slice();
      const LayoutPresetIndex = LayoutPresets.findIndex((p) => p._id === LayoutPresetId);
      if (LayoutPresetIndex !== -1) {
        LayoutPresets[LayoutPresetIndex] = {
          ...LayoutPresets[LayoutPresetIndex],
          ...updateParams,
        };
      }
      return LayoutPresets;
    });
  };
};

export const useGetLayoutPreset = (webinarId: string, layoutPresetId: string) => {
  const apiClient = useApiClient();
  return useQuery(getSingleLayoutPresetKey(webinarId, layoutPresetId), async () => {
    const { data } = await apiClient.get<{ data: { layoutPreset: LayoutPreset } }>(
      `/webinars/${webinarId}/layout-presets/${layoutPresetId}`,

    );
    return data.layoutPreset;
  });
};

export const useGetLayoutPresets = (webinarId: string, params?: LayoutPresetParams) => {
  const apiClient = useApiClient();
  return useQuery(
    getKey(webinarId, params),
    async () => {
      const requestParams = {
        include: ['user', 'sources'],
        ...params,
      };
      if (!requestParams.session) {
        requestParams.session = 'null';
      }
      const { data } = await apiClient.get<{ data: { layoutPresets: LayoutPreset[] } }>(
        `/webinars/${webinarId}/layout-presets`,
        requestParams,
      );
      return data.layoutPresets;
    },
    { enabled: !!webinarId },
  );
};

export const useCreateLayoutPreset = (webinarId: string, params?: LayoutPresetParams) => {
  const apiClient = useApiClient();
  const addLayoutPresetToQuery = useAddLayoutPresetToQuery(webinarId, params);
  return useMutation(
    async (layoutPreset: Partial<LayoutPreset>) => {
      const { data } = await apiClient.post<{ data: { layoutPreset: LayoutPreset } }>(
        `/webinars/${webinarId}/layout-presets`,
        layoutPreset,
      );
      return data.layoutPreset;
    },
    {
      onSuccess: (layoutPreset) => {
        addLayoutPresetToQuery(layoutPreset);
      },
    },
  );
};

export const useDeleteLayoutPreset = (webinarId: string, params?: LayoutPresetParams) => {
  const apiClient = useApiClient();
  const removeLayoutPresetFromQuery = useRemoveLayoutPresetFromQuery(webinarId, params);
  let layoutPresetId
  return useMutation(
    async (_layoutPresetId) => {
      layoutPresetId = _layoutPresetId
      await apiClient.delete(`/webinars/${webinarId}/layout-presets/${layoutPresetId}`);
    },
    {
      onSuccess: () => {
        removeLayoutPresetFromQuery(layoutPresetId);
      },
    },
  );
};

export const useUpdateLayoutPreset = (webinarId: string, layoutPresetId: string, params?: LayoutPresetParams) => {
  const apiClient = useApiClient();
  const updateLayoutPresetInQuery = useUpdateLayoutPresetInQuery(webinarId, params);
  return useMutation(
    async (updateParams: Partial<LayoutPreset>) => {
      await apiClient.patch(`/webinars/${webinarId}/layout-presets/${layoutPresetId}`, updateParams);
      return updateParams;
    },
    {
      onSuccess: (updateParams) => {
        updateLayoutPresetInQuery(layoutPresetId, updateParams);
      },
    },
  );
};

export const useLayoutPresetsPusherEvents = (
  webinarId: string,
  options: {
    onLayoutPresetPublish: (layoutPreset: LayoutPreset, sessionTitle?: string) => unknown;
    onLayoutPresetUpdated: (layoutPreset: LayoutPreset, sessionTitle?: string) => unknown;
  },
  params?: LayoutPresetParams,
) => {
  const addLayoutPreset = useAddLayoutPresetToQuery(webinarId, params);
  const updateLayoutPreset = useUpdateLayoutPresetInQuery(webinarId, params);
  const removeLayoutPreset = useRemoveLayoutPresetFromQuery(webinarId, params);
  const queryClient = useQueryClient();
  const isOrganizer = useIsOrganizerOfCurrentWebinar();
  const parameters = useParams();

  useEffect(() => {
    const channel = pusher.subscribe(`private-event-${webinarId}-LayoutPresets`);

    channel.bind(
      WEBINAR_PUSHER_EVENTS.LayoutPreset_UPDATED,
      (data: { layoutPreset: LayoutPreset; action: string; sessionTitle?: string }) => {
        if (!data.layoutPreset.isPublished && !isOrganizer) {
          removeLayoutPreset(data.layoutPreset._id);
          return;
        }

        const LayoutPresets = queryClient.getQueryData<LayoutPreset[]>(getKey(webinarId, params)) ?? [];
        if (LayoutPresets.find((p) => p._id === data.layoutPreset._id)) {
          updateLayoutPreset(data.layoutPreset._id, data.layoutPreset);
          if (data.action === 'update_LayoutPreset') {
            options.onLayoutPresetUpdated(data.layoutPreset, data.sessionTitle);
          }
        } else {
          addLayoutPreset(data.layoutPreset);
          if (data.layoutPreset.isPublished) {
            options.onLayoutPresetPublish(data.layoutPreset, data.sessionTitle);
          }
        }
      },
    );

    channel.bind(WEBINAR_PUSHER_EVENTS.LayoutPreset_DELETED, (data: { LayoutPresetId: string }) => {
      removeLayoutPreset(data.LayoutPresetId);
    });

    return () => {
      channel.unbind();
      channel.unsubscribe();
    };
  }, [
    webinarId,
    addLayoutPreset,
    updateLayoutPreset,
    removeLayoutPreset,
    isOrganizer,
    options,
    params,
    queryClient,
    parameters.sessionUrlKey,
  ]);
};

export const useLayoutPresetParams = () => {
  const { data: session } = useGetCurrentSession();
  const isOrganizer = useIsOrganizerOfCurrentWebinar();

  return useMemo(() => {
    let params: LayoutPresetParams = {};
    if (session?._id){
      params.session = session?._id;
    }
    else{
      params.session = null
    }


    return params;
  }, [isOrganizer, session]);
};
